import React, { Component } from "react";
import { Card } from "components/Card/Card.jsx";
import { Grid, Row, Table, Col, Pager } from "react-bootstrap";
import axios from 'components/axios';
import Button from 'components/CustomButton/CustomButton';
import Loader from 'react-loader-spinner'
import { mensajeError500 } from "variables/Variables.jsx";
import { rootRoute, gRoute } from "variables/DomainRoutes.jsx";
import ScoreReportModal from 'components/Modal/ScoreReportModal';
import logo from "assets/img/logo.png";
import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal.jsx';
import Checkbox from 'components/CustomCheckbox/CustomCheckbox';
import ModalExcel from 'components/ModalExcel';

class Score extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCheckboxes: new Set(),
      isLoading: false,
      scores: [],
      totalOfPages: 0,
      totalOfRecords: 0,
      currentPage: 1,
      showModalReport: false,
      logo: logo,
      scoreId: 0,
      urlExcel: '',
      scoreId: 0,
      showConfirmDelete: false,
      openModal: false
    };
    this.controlModal = this.controlModal.bind(this);
  }

  controlModal(show) {
    this.setState({ openModal: show || false });
  }

  async componentDidMount() {
    this.fetchScores();
    const institutionData = JSON.parse(localStorage.getItem('INSTITUTION'));
    if (institutionData.logo !== "") {
      this.setState({ logo: `${rootRoute}${institutionData.logo_folder}${institutionData.logo}` });
    }
  }

  fetchScores = async () => {
    const { currentPage } = this.state;
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('score/list', {
        current_page: currentPage,
      });
      this.setState({
        scores: response.data.scores.data,
        totalOfPages: response.data.scores.last_page,
        totalOfRecords: response.data.scores.total
      });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  toggleCheckbox(number) {
    const actual = this.state.selectedCheckboxes;
    if (actual.has(number)) {
      actual.delete(number);
    } else {
      actual.add(number);
    }
    this.setState({ selectedCheckboxes: actual })
  }
  joinIds = () => {
    const { selectedCheckboxes } = this.state;
    let ids = "";
    selectedCheckboxes.forEach((item) => {
      ids = ids + "," + item
    })
    return ids.substring(1);
  }

  handleDeleteMassive = async () => {
    this.setState({ isDeleting: true });
    try {
      await axios.post(`/score/delete/array`, {
        id: this.joinIds()
      });
      this.setState({ showConfirmDelete: false });
      this.fetchScores();
      this.state.selectedCheckboxes.clear()
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
      console.log('ERROR', err);
      this.setState({ isDeleting: false, showConfirmDelete: false });
    } finally {
      this.setState({ isDeleting: false });
    }
  }

  handleReport = (id) => {
    this.setState({ showModalReport: true, scoreId: id });
  }

  goToNextPage = () => {
    this.setState(
      (currentState) => ({ currentPage: currentState.currentPage + 1 }),
      () => { this.fetchScores() }
    );
  }

  goToPreviousPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage - 1 }),
      () => { this.fetchScores() }
    );
  }

  handleOpenConfirmDelete = (score) => {
    this.setState({
      showConfirmDelete: true,
      actionName: `${score.student.name} ${score.student.last_name}`,
      scoreId: score.id,
    });
  };

  handleDelete = async () => {
    const { scoreId } = this.state;
    this.setState({ isDeleting: true });
    try {
      await axios.post(`score/delete-by-cycle`, { score_id: scoreId });
      this.setState({ showConfirmDelete: false });
      this.fetchScores();
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
      console.log('ERROR', err);
      this.setState({ isDeleting: false, showConfirmDelete: false });
    } finally {
      this.setState({ isDeleting: false });
    }
  }

  render() {
    const {
      isLoading,
      scores,
      totalOfPages,
      totalOfRecords,
      currentPage,
      showModalReport,
      logo,
      scoreId,
      urlExcel,
      actionName,
      showConfirmDelete,
      loading,
      selectedCheckboxes,
      openModal
    } = this.state;
    return !isLoading ? (
      <div style={{ marginLeft: '30px', marginRight: '30px' }}>
        <ModalExcel url="/score/excel" isOpen={openModal} controlModal={this.controlModal} />
        <Card
          cardStyle={{
            boxShadow: 'none',
          }}
          content={
            <Grid fluid>
              <ConfirmDeleteModal
                onClose={() => this.setState({ showConfirmDelete: false })}
                show={showConfirmDelete}
                thingToRemoveType=" calificación de "
                thingToRemoveName={actionName}
                onConfirm={this.handleDelete}
              />
              <Row style={{ marginTop: "2%" }}>
                <Col md={12}>

                    <Button
                      bsStyle="primary"
                      pullRight
                      onClick={this.controlModal}
                      style={{
                        margin: "1%",
                        marginTop: "3%"
                      }}>
                      Exportar Excel</Button>
                  <Button
                    bsStyle="primary"
                    pullRight
                    style={{
                      margin: "1%",
                      marginTop: "3%"
                    }}
                    disabled={selectedCheckboxes.size === 0}
                    onClick={() => this.handleDeleteMassive()}>
                    Eliminar seleccionados</Button>
                </Col>
                <Col md={12}>
                  {showModalReport &&
                    <ScoreReportModal
                      onClose={() => this.setState({ showModalReport: false })}
                      open={showModalReport}
                      value={"jp"}
                      logo={logo}
                      scoreId={scoreId}
                    />}
                  <Table striped hover >
                    <thead>
                      <tr>
                        <th></th>
                        <th>Alumno</th>
                        <th>ciclo</th>
                        <th>nivel</th>
                        <th>grado</th>
                        <th>grupo</th>
                        <th>acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {scores.map((score) => {
                        return score.student && (
                          <tr key={score.id}>
                            <td>
                              <Checkbox
                                number={"score" + score.id}
                                onClick={() => { this.toggleCheckbox(score.id) }}
                              />
                            </td>
                            <td>{`${score.student.name} ${score.student.last_name}`}</td>
                            <td>{score.cycle && score.cycle.name}</td>
                            <td>{score.student.level && score.student.level.name}</td>
                            <td>{score.student.grade && score.student.grade.name}</td>
                            <td>{score.student.group && score.student.group.name}</td>
                            <td>
                              <Button fill bsStyle="primary" bsSize="sm" type="button" onClick={() => this.handleReport(score.id)}>
                                <i className="fa fa-file-pdf-o" /> Generar reporte
                              </Button>
                              <Button
                                bsStyle="danger"
                                bsSize="sm"
                                fill
                                title="Eliminar"
                                onClick={() => this.handleOpenConfirmDelete(score)}
                                style={{ marginLeft: "2%" }}
                              ><i className="fa fa-trash"></i> Eliminar</Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  {scores.length > 0 && (
                    <>
                      <Pager>
                        <Pager.Item
                          previous
                          disabled={currentPage === 1}
                          onClick={this.goToPreviousPage}
                        >
                          <i className="fa fa-chevron-left"></i>  Anterior
                        </Pager.Item>
                        <Pager.Item>

                          {currentPage}/{totalOfPages}

                        </Pager.Item>
                        <Pager.Item
                          disabled={currentPage === totalOfPages}
                          next
                          onClick={this.goToNextPage}
                        >
                          Siguiente <i className="fa fa-chevron-right"></i>
                        </Pager.Item>
                      </Pager>
                      <Pager>
                        <Pager.Item>
                          Total: {totalOfRecords} registros
                        </Pager.Item>
                      </Pager>
                    </>
                  )}
                </Col>
              </Row>
            </Grid>
          }
        />
      </div>
    ) : (
      <Loader
        type="Rings"
        color="#00BFFF"
        height={100}
        width={100}
        style={{ display: 'flex', justifyContent: 'center' }}
      />
    );
  }
}

export default Score;
