import React, { Component } from "react";
import { Card } from "components/Card/Card.jsx";
import { Grid, Row, Table, Col, Pager } from "react-bootstrap";
import { thMessageHistoryArray } from "variables/Variables.jsx";
import HeaderMessageTable from 'views/messages/components/HeaderMessageTable';
import axios from 'components/axios';
import Loader from 'react-loader-spinner'

class MessageTableHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      messages: [],
      totalOfPages: 0,
      currentPage: 1,
      totalOfRecords: 0
    };
  }

  async componentDidMount() {
    this.fetchMessages();
  }

  fetchMessages = async () => {
    const { currentPage } = this.state;
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('messages/history', {
        current_page: currentPage,
      });
      this.setState({
        messages: response.data.data,
        totalOfPages: response.data.last_page,
        totalOfRecords: response.data.total
      });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  goToNextPage = () => {
    this.setState(
      (currentState) => ({ currentPage: currentState.currentPage + 1 }),
      () => { this.fetchMessages() }
    );
  }

  goToPreviousPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage - 1 }),
      () => { this.fetchMessages() }
    );
  }

  changeTableData = (response) => {
    this.setState({
      messages: response.data.data,
      totalOfPages: response.data.last_page
    });
  }

  render() {
    const {
      isLoading,
      messages,
      totalOfPages,
      totalOfRecords,
      currentPage
    } = this.state;
    return !isLoading ? (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <Grid fluid>
              <HeaderMessageTable
                allowMassiveDelete="false"
                deleteDisabled={ false }
                route="/message/search/history"
                isHistory={ true }
                currentPage={ currentPage }
                changeTableData={ this.changeTableData }
                excelRoute={ '/messages/excel_history' }
              />
              <Row style={ { marginTop: "2%" } }>
                <Col md={ 12 }>
                  <Table striped hover >
                    <thead>
                      <tr>
                        { thMessageHistoryArray.map((prop, key) => {
                          return <th key={ key }>{ prop }</th>;
                        }) }
                      </tr>
                    </thead>
                    <tbody>
                      { messages.map((message) => {
                        return (
                          <tr key={ message.id }>
                            <td>
                              { message.emisor && `${message.emisor.name} ${message.emisor.last_name}` }
                            </td>
                            <td>
                              { message.tutor && `${message.tutor.name} ${message.tutor.last_name}` }
                            </td>
                            <td>
                              { message.student && `${message.student.name} ${message.student.last_name}` }
                            </td>

                            <td>{ message.student && message.student.level && message.student.level.name }</td>
                            <td>{ message.student && message.student.grade && message.student.grade.name }</td>
                            <td>{ message.student && message.student.group && message.student.group.name }</td>
                            <td>{ message.state === false ? <span className="label label-danger">No</span> : <span className="label label-success">Si</span> }</td>
                            <td>{ message.fast_response && message.fast_response.fast_response }</td>
                            <td>{ message.created_at }</td>
                            <td>{ message.viewed === null ? "-" : message.viewed }</td>
                          </tr>
                        );
                      }) }
                    </tbody>
                  </Table>
                  { messages.length > 0 && (
                    <>
                      <Pager>
                        <Pager.Item
                          previous
                          disabled={ currentPage === 1 }
                          onClick={ this.goToPreviousPage }
                        >
                          <i className="fa fa-chevron-left"></i>  Anterior
                      </Pager.Item>
                        <Pager.Item>

                          { currentPage }/{ totalOfPages }

                        </Pager.Item>
                        <Pager.Item
                          disabled={ currentPage === totalOfPages }
                          next
                          onClick={ this.goToNextPage }
                        >
                          Siguiente <i className="fa fa-chevron-right"></i>
                        </Pager.Item>
                      </Pager>
                      <Pager>
                        <Pager.Item>
                          Total: { totalOfRecords } registros
                        </Pager.Item>
                      </Pager>
                    </>
                  ) }
                </Col>
              </Row>
            </Grid>
          }
        />
      </div>
    ) : (
        <Loader
          type="Rings"
          color="#00BFFF"
          height={ 100 }
          width={ 100 }
          style={ { display: 'flex', justifyContent: 'center' } }
        />
      );
  }
}

export default MessageTableHistory;
