import React, { Component } from "react";
import {
  Grid
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import AttendanceList from 'views/attendance/AttendanceList';
import AttendanceDetail from 'views/attendance/AttendanceDetail';

class AttendanceStudents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0,
      attendanceData: {},
      currentPage: 1,
    };
  }

  handleBackToList = () => {
    this.setState({ key: 0 });
  };

  handleDetailAttendance = (attendanceData) => {
    this.setState({ key: 1, attendanceData: attendanceData });
  };

  goToNextPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage + 1 }));
  }

  goToPreviewsPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage - 1 }));
  }

  render() {
    const {
      key,
      attendanceData,
      currentPage
    } = this.state;

    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <Grid fluid>
              {
                {
                  0: (
                    <AttendanceList
                      detailAttendance={ this.handleDetailAttendance }
                      currentPage={ currentPage }
                      goToNextPage={ this.goToNextPage }
                      goToPreviousPage={ this.goToPreviewsPage }
                    />
                  ),
                  1: <AttendanceDetail returnToList={ this.handleBackToList } attendanceData={ attendanceData } />,
                }[key]
              }

            </Grid>
          }
        />
      </div>
    );
  }
}

export default AttendanceStudents;
