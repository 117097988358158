import React, { Component } from "react";
import {
  Row,
  Col,
  ControlLabel
} from "react-bootstrap";
import ContentMessage from "./components/ContentMessage.jsx";
import ButtonsConfirmCancel from "./components/ButtonsConfirmCancel.jsx";
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import HeaderType from "./components/HeaderType.jsx";
import { mensajeError500 } from "variables/Variables.jsx";
import ControlGroup from "./components/ControlGroup";
import { Card } from "components/Card/Card.jsx";
import axios from 'components/axios';
import Loader from 'react-loader-spinner'
import Select from 'react-select'
import LoadingModal from "components/Modal/LoadingModal";

class NewDegreeMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeId: '',
      subject: '',
      body: '',
      url1: '',
      url2: '',
      url3: '',
      url4: '',
      url5: '',
      attached1: '',
      attached2: '',
      attached3: '',
      attached4: '',
      attached5: '',
      programmed: false,
      fastResponse: false,
      repeat: false,
      period: '',
      dateSend: '',
      sendTime: '',
      sendTime1: '00',
      dateEnd: '',
      levelId: '',
      levels: [],
      gradeId: '',
      grades: [],
      groupId: '',
      groups: [],
      loadSelect: 0,
      isSubmitting: false,
      showNotification: false,
      notificationMessage: ''
    };
  }
  async componentDidMount() {
    this.fetchLevels();
  }

  fetchLevels = async () => {
    this.setState({ loadSelect: 1 });
    try {
      const response = await axios.post('/level/list');
      const newData = response.data.Levels.data.map((item) => {
        return { value: item.id, label: item.name }
      })
      this.setState({ levels: newData })
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ loadSelect: 0 });
    }
  }
  fetchGrades = async (level1) => {
    this.setState({ loadSelect: 2 });
    try {
      const response = await axios.post('/grade/by/level', { level_id: level1 });
      const newData = response.data.Grades.map((item) => {
        return { value: item.id, label: item.name }
      })
      newData.push({ value: '', label: "Todos los grados" })
      this.setState({ grades: newData })
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ loadSelect: 0 });
    }
  }

  fetchGroups = async () => {
    this.setState({ loadSelect: 3 });
    try {
      const response = await axios.post('/group/full_list');
      const newData = response.data.Groups.map((item) => {
        return { value: item.id, label: item.name }
      })
      newData.push({ value: '', label: "Todos los grupos" })
      this.setState({ groups: newData })
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ loadSelect: 0 });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleTypeId = (typeId) => {
    this.setState({ typeId: typeId });
  }

  handleContent = (p) => {
    this.setState({ [p.target.name]: p.target.value });
  }
  onEditorChange = (evt) => {
    this.setState({
      body: evt.editor.getData()
    });
  }

  joinAttached = () => {
    const {
      attached1,
      attached2,
      attached3,
      attached4,
      attached5,
    } = this.state;
    let attached_str = "";
    let attacheds = [];
    attacheds.push(attached1)
    attacheds.push(attached2)
    attacheds.push(attached3)
    attacheds.push(attached4)
    attacheds.push(attached5)
    for (let index = 0; index < 5; index++) {
      if (attacheds[index] !== "") {
        if (index === 0) {
          attached_str = attached_str + attacheds[index]
        } else {
          attached_str = attached_str + "," + attacheds[index]
        }
      }
    }
    return attached_str
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const {
      typeId,
      subject,
      body,
      url1,
      url2,
      url3,
      url4,
      url5,
      programmed,
      fastResponse,
      repeat,
      dateSend,
      sendTime,
      sendTime1,
      dateEnd,
      period,
      levelId,
      gradeId,
      groupId
    } = this.state;
    if (typeId === '') {
      this.setState({
        showNotification: true,
        notificationMessage: "Debe seleccionar una opción para el campo TIPO DE MENSAJE"
      });
      return;
    }
    if (levelId === '') {
      this.setState({
        showNotification: true,
        notificationMessage: "Debe seleccionar una opción para el campo NIVEL"
      });
      return;
    }
    if (programmed && dateSend === '') {
      this.setState({
        showNotification: true,
        notificationMessage:
          "Debe seleccionar una opción para el campo FECHA DE ENVÍO, ya que escogiste la opción MENSAJE PROGRAMADO."
      });
      return;
    }
    if (programmed && sendTime === '') {
      this.setState({
        showNotification: true,
        notificationMessage:
          "Debe seleccionar una opción para el campo HORA DE ENVÍO, ya que escogiste la opción MENSAJE PROGRAMADO."
      });
      return;
    }
    if (repeat && period === '') {
      this.setState({
        showNotification: true,
        notificationMessage:
          "Debe seleccionar una opción para el campo PERIODO, ya que escogiste la opción repetir mensaje."
      });
      return;
    }
    if (repeat && dateEnd === '') {
      this.setState({
        showNotification: true,
        notificationMessage:
          "Debe seleccionar una opción para el campo FECHA FIN, ya que escogiste la opción repetir mensaje."
      });
      return;
    }
    let toBackDateSend = '';
    if (!programmed && dateSend === '') {
      toBackDateSend = ''
    } else {
      var porciones = sendTime.split(':');
      var hour = porciones[0] + ":" + sendTime1;
      toBackDateSend = dateSend + " " + hour + ":00"
    }
    this.setState({ isSubmitting: true });
    try {
      if (gradeId === '' && groupId === '') {
        if (programmed) {
          if (repeat) {
            await axios.post('message/levels', {
              body,
              subject,
              type_id: typeId,
              url1,
              url2,
              url3,
              url4,
              url5,
              programmed,
              fast_response: fastResponse,
              repeat,
              date_send: toBackDateSend,
              date_end: dateEnd,
              period,
              level_id: levelId,
              attachment: this.joinAttached()
            });
          } else {
            await axios.post('message/levels', {
              body,
              subject,
              type_id: typeId,
              url1,
              url2,
              url3,
              url4,
              url5,
              programmed,
              fast_response: fastResponse,
              repeat,
              date_send: toBackDateSend,
              level_id: levelId,
              attachment: this.joinAttached()
            });
          }
        } else {
          await axios.post('message/levels', {
            body,
            subject,
            type_id: typeId,
            url1,
            url2,
            url3,
            url4,
            url5,
            programmed,
            fast_response: fastResponse,
            repeat,
            level_id: levelId,
            attachment: this.joinAttached()
          });
        }
      } else {
        if (groupId === '') {
          if (programmed) {
            if (repeat) {
              await axios.post('message/levels', {
                body,
                subject,
                type_id: typeId,
                url1,
                url2,
                url3,
                url4,
                url5,
                programmed,
                fast_response: fastResponse,
                repeat,
                date_send: toBackDateSend,
                date_end: dateEnd,
                period,
                level_id: levelId,
                grade_id: gradeId,
                attachment: this.joinAttached()
              });
            } else {
              await axios.post('message/levels', {
                body,
                subject,
                type_id: typeId,
                url1,
                url2,
                url3,
                url4,
                url5,
                programmed,
                fast_response: fastResponse,
                repeat,
                date_send: toBackDateSend,
                level_id: levelId,
                grade_id: gradeId,
                attachment: this.joinAttached()
              });
            }
          } else {
            await axios.post('message/levels', {
              body,
              subject,
              type_id: typeId,
              url1,
              url2,
              url3,
              url4,
              url5,
              programmed,
              fast_response: fastResponse,
              repeat,
              level_id: levelId,
              grade_id: gradeId,
              attachment: this.joinAttached()
            });
          }
        } else {
          if (programmed) {
            if (repeat) {
              await axios.post('message/levels', {
                body,
                subject,
                type_id: typeId,
                url1,
                url2,
                url3,
                url4,
                url5,
                programmed,
                fast_response: fastResponse,
                repeat,
                date_send: toBackDateSend,
                date_end: dateEnd,
                period,
                level_id: levelId,
                grade_id: gradeId,
                group_id: groupId,
                attachment: this.joinAttached()
              });
            } else {
              await axios.post('message/levels', {
                body,
                subject,
                type_id: typeId,
                url1,
                url2,
                url3,
                url4,
                url5,
                programmed,
                fast_response: fastResponse,
                repeat,
                date_send: toBackDateSend,
                level_id: levelId,
                grade_id: gradeId,
                group_id: groupId,
                attachment: this.joinAttached()
              });
            }
          } else {
            await axios.post('message/levels', {
              body,
              subject,
              type_id: typeId,
              url1,
              url2,
              url3,
              url4,
              url5,
              programmed,
              fast_response: fastResponse,
              repeat,
              level_id: levelId,
              grade_id: gradeId,
              group_id: groupId,
              attachment: this.joinAttached()
            });
          }
        }
      }
      this.props.history.replace("/admin/message/index")
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: "Durante el envío se encontró el siguiente error: " + err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
    } finally {
      this.setState({ isSubmitting: false });
    }
  }

  render() {
    const {
      showNotification,
      notificationMessage,
      levels,
      grades,
      groups,
      loadSelect,
      isSubmitting
    } = this.state;

    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <form onSubmit={ this.handleSubmit }>
              <Row>
                <Col md={ 12 }>
                  <LoadingModal
                    show={ isSubmitting }
                    text="Enviando mensajes ..."
                  />
                  <Row style={ { paddingLeft: "30px" } }>
                    <h4>Enviar mensaje a nivel, grado o grupo</h4>
                    { showNotification &&
                      <ErrorNotification
                        notificationMessage={ notificationMessage }
                        closeNotification={ () => this.setState({ showNotification: false }) }
                      />
                    }
                    <Col md={ 6 }>
                      <HeaderType onSelectTypeId={ this.handleTypeId } />
                      <Row>
                        <Col md={ 4 }>
                          <ControlLabel > NIVEL </ControlLabel>
                          { loadSelect === 1 ?
                            <Loader
                              type="Rings"
                              color="#00BFFF"
                              height={ 30 }
                              width={ 30 }
                              style={ { display: 'flex', justifyContent: 'center' } }
                            />
                            :
                            <Select options={ levels }
                              name="levelId" className="basic-single"
                              classNamePrefix="select"
                              placeholder="nivel ..."
                              onChange={ (e) => { this.setState({ 'levelId': e.value }); this.fetchGrades(e.value) } }
                            />
                          }
                        </Col>
                        <Col md={ 4 }>
                          <ControlLabel> GRADO </ControlLabel>
                          { loadSelect === 2 ?
                            <Loader
                              type="Rings"
                              color="#00BFFF"
                              height={ 30 }
                              width={ 30 }
                              style={ { display: 'flex', justifyContent: 'center' } }
                            />
                            :
                            <Select options={ grades }
                              name="gradeId" className="basic-single"
                              classNamePrefix="select"
                              onChange={ (e) => { this.setState({ 'gradeId': e.value }); this.fetchGroups() } }
                              placeholder="Todos los grados"
                              isDisabled={ grades.length === 0 }
                            />
                          }
                        </Col>
                        <Col md={ 4 }>
                          <ControlLabel > GRUPO </ControlLabel>
                          { loadSelect === 3 ?
                            <Loader
                              type="Rings"
                              color="#00BFFF"
                              height={ 30 }
                              width={ 30 }
                              style={ { display: 'flex', justifyContent: 'center' } }
                            />
                            :
                            <Select options={ groups }
                              name="groupId" className="basic-single"
                              classNamePrefix="select"
                              onChange={ (e) => this.setState({ 'groupId': e.value }) }
                              placeholder="Todos los grupos"
                              isDisabled={ groups.length === 0 || this.state.gradeId === '' }
                            />
                          }
                        </Col>
                      </Row>
                      <ContentMessage onChange={ this.handleContent } onEditorChange={ this.onEditorChange } />
                    </Col>
                    <Col md={ 6 }>
                      <ControlGroup onChange={ this.handleContent } />
                    </Col>
                    <ButtonsConfirmCancel history={ this.props.history } />
                  </Row>
                </Col>
              </Row>
            </form>
          }
        />
      </div>
    );
  }
}

export default NewDegreeMessage;
