import React, { Component } from "react";
import { Row, Table, Col, Pager } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import Loader from 'react-loader-spinner'
import { mensajeError500 } from "variables/Variables.jsx";
import { gRoute } from "variables/DomainRoutes.jsx";
import axios from 'components/axios';
import ModalQRAttendance from './ModalQRAttendance';
import SearchInputStudent from 'components/Search/SearchInputStudent';

class AttendanceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isDeleting: false,
      showConfirmDelete: false,
      actionName: "",
      selectedAttendance: null,
      attendances: [],
      totalOfPages: 0,
      totalOfRecords: 0,
      showNotification: false,
      notificationMessage: '',
      showQR: false,
      tokenQR: '',
      urlQR: '',
      loadingQR: false
    };
  }
  async componentDidMount() {
    this.fetchAttendances();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentPage !== this.props.currentPage) {
      this.fetchExtras();
    }
  }

  fetchAttendances = async () => {
    const { currentPage } = this.props;
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('/student/list', {
        current_page: currentPage,
      });
      this.setState({
        attendances: response.data.students.data,
        totalOfPages: response.data.students.last_page,
        totalOfRecords: response.data.students.total
      });
      this.handleExportQR()
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
      } else {
        console.log('ERROR', err);
      }
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleShowQR = async (attendance) => {
    this.setState({ tokenQR: attendance.code, selectedAttendance: attendance });
    this.setState({ showQR: true });
  };

  handleCloseQR = () => { this.setState({ showQR: false }); };

  changeTableData = (response) => {
    this.setState({
      attendances: response.data.students.data,
      totalOfPages: response.data.students.last_page,
      totalOfRecords: response.data.students.total
    });
  }

  handleExportQR = async () => {
    this.setState({ loadingQR: true });
    const response = await axios.post(`/student/get-codes`);
    this.setState({ urlQR: response.data.route, loadingQR: false });
  }


  render() {
    const {
      detailAttendance,
      currentPage,
      goToNextPage,
      goToPreviousPage,
    } = this.props;
    const {
      isLoading,
      totalOfPages,
      totalOfRecords,
      attendances,
      selectedAttendance,
      showQR,
      tokenQR,
      loadingQR,
      urlQR
    } = this.state;
    return !isLoading ? (
      <>
        { showQR &&
          <ModalQRAttendance
            onClose={ this.handleCloseQR }
            open={ showQR }
            value={ tokenQR }
            attendanceData={ selectedAttendance }
          /> }
        <Col md={ 12 }>
          <SearchInputStudent
            route={ '/student/search' }
            currentPage={ currentPage }
            changeTableData={ this.changeTableData }
          />
        </Col>
        <Col md={ 2 } style={ { paddingLeft: "3%" } }>
          <a
            href={ urlQR !== '' && gRoute + urlQR }
            target="_blank"
            rel="noopener noreferrer">
            <Button
              bsStyle="primary"
              fill
              block
              disabled={ urlQR == '' ? true : false }
              onClick={ () => this.handleExportQR() }
            >
              { loadingQR && (
                <i
                  className="fa fa-refresh fa-spin"
                  style={ { marginRight: "5px" } }
                />
              ) }Descargar QRs</Button>
          </a>
        </Col>
        <Row>
          <Col md={ 12 }>
            <Table striped hover >
              <thead>
                <tr>
                  <th>Nombres </th>
                  <th>Apellidos </th>
                  <th>Matrícula </th>
                  <th>Padre</th>
                  <th>Sexo </th>
                  <th>Nivel</th>
                  <th>Grado</th>
                  <th>Grupo</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                { attendances.map((attendance) => {
                  return (
                    <tr key={ attendance.id }>
                      <td>
                        { attendance.name }
                      </td>
                      <td>
                        { attendance.last_name }
                      </td>
                      <td>
                        { attendance.code }
                      </td>
                      <td>
                        { attendance.tutor && `${attendance.tutor.name} ${attendance.tutor.last_name}` }
                      </td>
                      <td> { attendance.gender === true ? "F" : "M" } </td>
                      <td>{ attendance.level && attendance.level.name }</td>
                      <td>{ attendance.grade && attendance.grade.name }</td>
                      <td>{ attendance.group && attendance.group.name }</td>
                      <td>
                        <Button bsStyle="warning" bsSize="sm" fill title="QR" onClick={ () => this.handleShowQR(attendance) }><i className="fa fa-qrcode"></i></Button>
                        <Button bsStyle="info" bsSize="sm" fill title="Ver detalles" onClick={ () => detailAttendance(attendance) } ><i className="fa fa-search"></i></Button>
                      </td>
                    </tr>
                  );
                }) }
              </tbody>
            </Table>
            { attendances.length > 0 && (
              <>
                <Pager>
                  <Pager.Item
                    previous
                    disabled={ currentPage === 1 }
                    onClick={ goToPreviousPage }
                  >
                    <i className="fa fa-chevron-left"></i>  Anterior
                      </Pager.Item>
                  <Pager.Item>

                    { currentPage }/{ totalOfPages }

                  </Pager.Item>
                  <Pager.Item
                    disabled={ currentPage === totalOfPages }
                    next
                    onClick={ goToNextPage }
                  >
                    Siguiente <i className="fa fa-chevron-right"></i>
                  </Pager.Item>
                </Pager>
                <Pager>
                  <Pager.Item>
                    Total: { totalOfRecords } registros
                </Pager.Item>
                </Pager>
              </>
            ) }
          </Col>
        </Row>
      </>
    ) : (
        <Loader
          type="Rings"
          color="#00BFFF"
          height={ 100 }
          width={ 100 }
          style={ { display: 'flex', justifyContent: 'center' } }
        />
      );
  }
}

export default AttendanceList;
