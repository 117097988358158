import * as axios from 'axios';
// import {Toast} from 'native-base';

var instance = axios.create();
instance.defaults.baseURL = 'https://difemapi.aplicacionescolar.net/api/v1/';

//instance.defaults.baseURL = 'https://api.aplicacionescolar.net/api/v1/';
//'http://api.airefon.xyz/api/v1/';
//instance.defaults.timeout = 120000;
instance.defaults.headers.post['Content-Type'] = 'application/json';
//instance.defaults.headers.post['Accept'] = 'application/json';
instance.defaults.crossdomain = true;

//...
//and other options
instance.interceptors.request.use(async (config) => {
  // Do something before request is sent
  const finalConfig = config;
  const token = await localStorage.getItem('TOKEN');
  finalConfig.headers.post['Authorization'] = `Bearer ${token}`;
  return finalConfig;
});

export { instance as default };

export const handleResponse = (response, onSucces, showSnack) => {
  console.log('Success:', response.data);
};

export const handleError = error => {
  console.log('Error:', error);
};
