import React from 'react'
import { Grid } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import TutorQuestionnaireList from './TutorQuestionnaireList';

class QuestionnaireMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      studentData: {},
      currentPage: 1
    };
  }
    
  render() { 
    const {
      currentPage
    } = this.state;

    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <Grid fluid>
              <TutorQuestionnaireList
                currentPage={ currentPage }
              />
            </Grid>
          }
        />
      </div>
    )
  }
}

export default QuestionnaireMenu
