import React from 'react'

import { Table, Col, Row, Pager } from "react-bootstrap";
import { thParentArray, mensajeError500 } from "variables/Variables.jsx";
import { gRoute } from "variables/DomainRoutes.jsx";
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import Checkbox from 'components/CustomCheckbox/CustomCheckbox';
import SearchInput from 'components/Search/SearchInputFixed';
import Button from 'components/CustomButton/CustomButton';
import ModalQR from './ModalQR';
import axios from 'components/axios';
import Loader from 'react-loader-spinner'
import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal.jsx';
import styles from './styles'
import ModalExcel from 'components/ModalExcel'

class ParentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCheckboxes: new Set(),
      showQR: false,
      isLoading: false,
      isDeleting: false,
      totalOfPages: 0,
      totalOfRecords: 0,
      parents: [],
      showConfirmDelete: false,
      actionName: "",
      selectedParent: {},
      showNotification: false,
      notificationMessage: '',
      tokenQR: '',
      urlExcel: '',
      searchActive: false,
      searchShowNotification: false,
      searchTarget: '',
      searchQuerySearch: undefined,
      searchShowNotification: false,
      searchNotificationType: '',
      searchNotificationMessage: '',
      searchIsLoading: false,
      openModal: false
    };
    this.controlModal = this.controlModal.bind(this);
  }

  controlModal(show) {
    this.setState({ openModal: show || false });
  }

  toggleCheckbox(number) {
    const actual = this.state.selectedCheckboxes;
    if (actual.has(number)) {
      actual.delete(number);
    } else {
      actual.add(number);
    }
    this.setState({ selectedCheckboxes: actual })
  }

  handleShowQR = async (tutor) => {
    try {
      const response = await axios.post('/user/qr_string/' + tutor.id);
      this.setState({ tokenQR: response.data.qr_string, selectedParent: tutor });
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
      }
      console.log('ERROR', err);
    } finally {
      this.setState({ showQR: true });
    }
  };
  handleCloseQR = () => { this.setState({ showQR: false }); };

  async componentDidMount() {
    this.fetchParents();
  }

  fetchParents = async () => {
    const { currentPage } = this.state;
    this.setState({ isLoading: true });
    try {
      let response = {};
      if (currentPage === undefined) {
        this.setState({ currentPage: this.props.currentPage })
        response = await axios.post('tutor/list', {
          current_page: this.props.currentPage,
        });
      } else {
        response = await axios.post('tutor/list', {
          current_page: this.state.currentPage,
        });
      }
      this.setState({
        parents: response.data.tutors.data,
        totalOfPages: response.data.tutors.last_page,
        totalOfRecords: response.data.tutors.total
      });
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
      }
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }


  handleOpenConfirmDelete = (parent) => {
    this.setState({
      showConfirmDelete: true,
      actionName: `${parent.name} ${parent.last_name}`,
      selectedParent: parent,
    });
  };

  handleDelete = async () => {
    const { selectedParent } = this.state;
    this.setState({ isDeleting: true });
    try {
      await axios.post(`tutor/delete/${selectedParent.id}`);
      this.setState({ showConfirmDelete: false });
      this.fetchParents();
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
      }
      console.log('ERROR', err);
      this.setState({ isDeleting: false, showConfirmDelete: false });
    } finally {
      this.setState({ isDeleting: false });
    }
  }

  joinIds = () => {
    const { selectedCheckboxes } = this.state;
    let ids = "";
    selectedCheckboxes.forEach((item) => {
      ids = ids + "," + item
    })
    return ids.substring(1);
  }

  handleDeleteMassive = async () => {
    this.setState({ isDeleting: true });
    try {
      await axios.post(`tutor/masive/delete/`, {
        id: this.joinIds()
      });
      this.setState({ showConfirmDelete: false });
      this.fetchParents();
      this.state.selectedCheckboxes.clear()
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
      }
      console.log('ERROR', err);
      this.setState({ isDeleting: false, showConfirmDelete: false });
    } finally {
      this.setState({ isDeleting: false });
    }
  }

  fetchSearch = async (target, querySearch, route, currentPage) => {
    this.setState({ searchActive: true })
    this.setState({ searchIsLoading: true });

    try {
      var response = {}
      if (querySearch === true) {
        response = await axios.post(route, {
          page: currentPage,
          query: target
        });
      } else {
        response = await axios.post(route, {
          current_page: currentPage,
          name: target
        });
      }

      if (response.status === 200) {
        this.setState({
          searchShowNotification: true,
          searchNotificationType: "info",
          searchNotificationMessage: "Búsqueda finalizada. Se encontraron " + response.data.tutors.total + " coincidecias.",
        });
      }
      this.setState({
        parents: response.data.tutors.data,
        totalOfPages: response.data.tutors.last_page
      });
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
      } else {
        console.log("ERROR", err)
      }
    } finally {
      this.setState({ searchIsLoading: false });
    }
  }

  onClickPagination = () => {
    const { searchTarget, searchRoute, searchQuerySearch, currentPage } = this.state
    this.fetchSearch(searchTarget, searchQuerySearch, searchRoute, currentPage);
  }

  onClickButtonSearch = (target, route, querySearch) => {
    this.setState({ searchTarget: target, searchRoute: route, currentPage: 1, searchQuerySearch: querySearch });
    this.fetchSearch(target, querySearch, route, 1);
  }

  render() {
    const {
      showQR,
      parents,
      isLoading,
      currentPage,
      totalOfPages,
      showConfirmDelete,
      actionName,
      showNotification,
      notificationMessage,
      tokenQR,
      selectedParent,
      selectedCheckboxes,
      totalOfRecords,
      urlExcel,
      loading,
      openModal
    } = this.state;

    const {
      newParent,
      detailParent,
      editParent,
      newChild,
    } = this.props;
    return !isLoading ? (
      <>
        <Row>
          <ModalExcel url="/tutor/excel" isOpen={openModal} controlModal={this.controlModal} />
          {showQR &&
            <ModalQR
              onClose={this.handleCloseQR}
              open={showQR}
              value={tokenQR}
              tutorData={selectedParent}
            />}
          <Col md={12}>
            <Col md={6} style={{ paddingLeft: "2%" }}>
              <Button bsStyle="primary" fill style={styles.topButton} onClick={() => newParent()}>
                Nuevo Padre
              </Button>
            </Col>
            <Col md={6}>
              <Button
                bsStyle="primary"
                pullRight
                onClick={() => this.controlModal(true)}
                style={styles.topButton}
              >
                Exportar Excel
              </Button>
              <Button
                bsStyle="primary"
                pullRight
                style={styles.topButton}
                disabled={selectedCheckboxes.size === 0}
                onClick={() => this.handleDeleteMassive()}>
                Eliminar seleccionados</Button>
            </Col>
          </Col>
          <Col md={8}>
            <SearchInput
              route={'/tutor/search'}
              search={this.onClickButtonSearch}
              fetchDetails={this.state}
              currentPage={currentPage}
              closeNotification={() => this.setState({ ...this.state, searchShowNotification: false })}
            />
          </Col>
          <ConfirmDeleteModal
            onClose={() => this.setState({ showConfirmDelete: false })}
            show={showConfirmDelete}
            thingToRemoveType="padre"
            thingToRemoveName={actionName}
            onConfirm={this.handleDelete}
          />

          <Col md={12} style={{ padding: "2%" }}>
            {showNotification &&
              <ErrorNotification
                notificationMessage={notificationMessage}
                closeNotification={() => this.setState({ showNotification: false })}
              />
            }
            <Table striped hover >
              <thead>
                <tr>
                  {thParentArray.map((prop, key) => {
                    return <th key={key}>{prop}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {parents.map((parent) => {
                  return (
                    <tr key={parent.id}>
                      <td>
                        <Checkbox
                          number={"parent" + parent.id}
                          onClick={() => { this.toggleCheckbox(parent.id) }}
                        />
                      </td>
                      <td>{parent.name}</td>
                      <td>{parent.last_name}</td>
                      <td>{parent.email && parent.email}</td>
                      <td>{parent.fcm_count > 0 ? "Si" : "No"}</td>
                      <td>
                        <Button bsStyle="primary" bsSize="sm" fill title="Asignar hijo" onClick={() => newChild(parent, currentPage)}><i className="fa fa-user-plus"></i></Button>
                        <Button bsStyle="info" bsSize="sm" fill title="Ver detalles" onClick={() => detailParent(parent, currentPage)}><i className="fa fa-search"></i></Button>
                        <Button bsStyle="success" bsSize="sm" fill title="Editar" onClick={() => editParent(parent, currentPage)}><i className="fa fa-pencil"></i></Button>
                        <Button bsStyle="warning" bsSize="sm" fill title="QR" onClick={() => this.handleShowQR(parent)}><i className="fa fa-qrcode"></i></Button>
                        <Button bsStyle="danger" bsSize="sm" fill title="Eliminar" onClick={() => this.handleOpenConfirmDelete(parent)}><i className="fa fa-trash"></i></Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {parents.length > 0 && (
              <>
                <Pager>
                  <Pager.Item
                    previous
                    disabled={(currentPage === 1) || this.state.searchIsLoading}
                    onClick={() => {
                      this.setState((currentState) => ({ currentPage: currentState.currentPage - 1 }), this.state.searchActive ? this.onClickPagination : this.fetchParents);
                    }}
                  >
                    <i className="fa fa-chevron-left"></i> Anterior
                  </Pager.Item>
                  <Pager.Item>
                    {currentPage}/{totalOfPages}
                  </Pager.Item>
                  <Pager.Item
                    disabled={(currentPage === totalOfPages) || this.state.searchIsLoading}
                    next
                    onClick={() => {
                      this.setState((currentState) => ({ currentPage: currentState.currentPage + 1 }), this.state.searchActive ? this.onClickPagination : this.fetchParents);
                    }}
                  >
                    Siguiente<i className="fa fa-chevron-right"></i>
                  </Pager.Item>
                </Pager>
                <Pager>
                  <Pager.Item>
                    Total: {totalOfRecords} registros
                  </Pager.Item>
                </Pager>
              </>
            )}
          </Col>
        </Row>
      </>
    ) : (
      <Loader
        type="Rings"
        color="#00BFFF"
        height={100}
        width={100}
        style={{ display: 'flex', justifyContent: 'center' }}
      />
    );
  }
}
export default ParentList