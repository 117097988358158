import React from 'react'
import { Modal, Grid, Row, Col, FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import { RoleModulesArray1, RoleModulesArray2 } from "variables/Variables.jsx";
import Button from 'components/CustomButton/CustomButton';
import Checkbox from 'components/CustomCheckbox/CustomCheckbox';
import { mensajeError500 } from "variables/Variables.jsx";
import axios from 'components/axios';
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
//Cuando esten integrados los modales de "EditRole" y "NewRole" ver si se puede abstraer en solo una clase

class modalNewRole extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPrivileges: new Set(),
      showNotification: false,
      notificationMessage: '',
      name: ''
    };
  }

  toggleCheckbox(number) {
    const actual = this.state.selectedPrivileges;
    if (actual.has(number)) {
      actual.delete(number);
    } else {
      actual.add(number);
    }
    this.setState({ selectedPrivileges: actual })
    console.log(this.state.selectedPrivileges)
  }

  joinIds = () => {
    const { selectedPrivileges } = this.state;
    let ids = "";
    selectedPrivileges.forEach((item) => {
      ids = ids + item
    })
    return ids;
  }

  handleAddRole = async () => {
    const {
      name
    } = this.state;
    try {
      await axios.post(`/rol/add`, {
        name,
        privileges: this.joinIds()
      });
      this.props.onClose()
      if (this.props.onUserForm) {
        this.props.reloadRoles()
      }
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
      console.log('ERROR', err);
      this.setState({ isDeleting: false });
    } finally {
      this.setState({ isDeleting: false });
    }
  }

  render() {
    const {
      showNotification,
      notificationMessage
    } = this.state;
    const {
      open,
      onClose
    } = this.props;
    return (
      <Modal show={ open } onHide={ onClose }>
        <Modal.Header closeButton>
          <Modal.Title>Nuevo Rol</Modal.Title>
        </Modal.Header>
        <Modal.Body style={ { wordWrap: "break-word" } }>
          <Grid>
            <Row>
              <Col md={ 5 }>
                { showNotification &&
                  <ErrorNotification
                    notificationMessage={ notificationMessage }
                    closeNotification={ () => this.setState({ showNotification: false }) }
                  />
                }
              </Col>
            </Row>
            <Row>
              <Col md={ 3 }>
                <FormGroup>
                  <ControlLabel style={ { fontWeight: "normal" } }> NOMBRE </ControlLabel>
                  <FormControl type="text" name="name" onChange={ (e) => this.setState({ name: e.target.value }) } />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={ 12 }>
                <ControlLabel style={ { fontWeight: "normal" } }> PERMISOS </ControlLabel>
              </Col>
              <Col md={ 3 }>
                {
                  RoleModulesArray1.map((prop) => {
                    return <Checkbox number={ `new_${prop.letter}` } label={ prop.name } onClick={ () => { this.toggleCheckbox(prop.letter) } } />;
                  })
                }
              </Col>
              <Col md={ 3 }>
                {
                  RoleModulesArray2.map((prop) => {
                    return <Checkbox number={ `new_${prop.letter}` } label={ prop.name } onClick={ () => { this.toggleCheckbox(prop.letter) } } />;
                  })
                }
              </Col>
            </Row>
          </Grid>

        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="success" fill onClick={ () => this.handleAddRole() }> <i className="fa fa-save"></i> Crear Nuevo Rol </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};
export default modalNewRole;