import React from "react";
import { Row, Col, FormGroup, ControlLabel } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import Button from 'components/CustomButton/CustomButton';
import styles from './styles';
import axios from 'components/axios';
import Loader from 'react-loader-spinner';
import { generalRoute } from "variables/DomainRoutes.jsx";

class ConfSchool extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      schoolData: {},
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('institution/details');
      this.setState({ schoolData: response.data.institution });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { isLoading, schoolData } = this.state;
    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={ !isLoading ? (
            <>
              <Row>
                <Col md={ 12 } style={ { padding: "2%", marginLeft: "3%" } }>
                  <Row >
                    <Col md={ 10 }>
                      <FormGroup>
                        <Col md={ 12 }>
                          <ControlLabel> NOMBRE </ControlLabel>
                        </Col>
                        <Col md={ 12 } style={ styles.dataN1 }>{ schoolData && schoolData.name }</Col>
                      </FormGroup>
                      <FormGroup>
                        <Col md={ 12 }>
                          <ControlLabel> DESCRIPCIÓN </ControlLabel>
                        </Col>
                        <Col md={ 12 } style={ styles.dataN1 } >
                          { schoolData && schoolData.description }
                        </Col>
                      </FormGroup>
                      <FormGroup>
                        <Col md={ 12 }>
                          <ControlLabel> FECHA DE INICIO DE LICENCIA </ControlLabel>
                        </Col>
                        <Col md={ 12 } style={ styles.dataN1 }>{ schoolData && schoolData.license_start_date }</Col>
                      </FormGroup>
                      <FormGroup>
                        <Col md={ 12 }>
                          <ControlLabel> DATOS BANCARIOS </ControlLabel>
                        </Col>
                        <Col md={ 6 }>
                          <Col md={ 12 }>
                            <Col md={ 4 }> <ControlLabel > BANCO </ControlLabel> </Col>
                            <Col md={ 8 } style={ styles.dataN2 }>{ schoolData && schoolData.bank }</Col>
                          </Col>
                          <Col md={ 12 }>
                            <Col md={ 4 }> <ControlLabel > CUENTA </ControlLabel> </Col>
                            <Col md={ 8 } style={ styles.dataN2 }>{ schoolData && schoolData.bank_account }</Col>
                          </Col>
                          <Col md={ 12 }>
                            <Col md={ 4 }> <ControlLabel > BENEFICIARIO </ControlLabel> </Col>
                            <Col md={ 8 } style={ styles.dataN2 } >{ schoolData && schoolData.beneficiary_name }</Col>
                          </Col>
                        </Col>
                      </FormGroup>
                      <FormGroup>
                        <Col md={ 12 }>
                          <ControlLabel> POLÍTICAS DE PRIVACIDAD </ControlLabel>
                        </Col>
                        <Col md={ 8 } style={ styles.dataN1 }>
                          {
                            schoolData.privacy_policies !== "" ?
                              <a
                                href={ schoolData && generalRoute + schoolData.privacy_policies }
                                target="_blank"
                                rel="noopener noreferrer">
                                haga click para ver
                          </a>
                              :
                              "No cuenta con políticas de privacidad"
                          }

                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={ 12 } style={ { marginLeft: "5%" } }>
                  <Button bsStyle="info" fill type="button" style={ styles.saveButton } onClick={ () => this.props.history.replace('/admin/conf/edit-school', { schoolData }) }>
                    <i className="fa fa-edit"></i> Editar
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
              <Loader
                type="Rings"
                color="#00BFFF"
                height={ 100 }
                width={ 100 }
                style={ { display: 'flex', justifyContent: 'center' } }
              />
            )
          } />
      </div>
    )
  }
}

export default ConfSchool;
