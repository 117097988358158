import React from 'react'
import { roles, mensajeError500 } from "variables/Variables.jsx";
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import { Row, Table, Col, Pager } from "react-bootstrap";
import SearchInput from 'components/Search/SearchInput';
import Button from 'components/CustomButton/CustomButton';
import axios from 'components/axios';
import Loader from 'react-loader-spinner'
import ChangePassUserModal from 'components/Modal/ChangePassUserModal.jsx';
import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal.jsx';

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0,
      isLoading: false,
      isDeleting: false,
      totalOfPages: 0,
      users: [],
      showChangePassUserModal: false,
      showConfirmDelete: false,
      actionName: "",
      selectedUser: {},
      notificationMessage: '',
      showNotification: false,
      totalOfRecords: 0
    };
  }

  async componentDidMount() {
    this.fetchUsers();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentPage !== this.props.currentPage) {
      this.fetchUsers();
    }
  }

  fetchUsers = async () => {
    const { currentPage } = this.props;
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('user/list', {
        current_page: currentPage,
      });
      this.setState({
        users: response.data.users.data,
        totalOfPages: response.data.users.last_page,
        totalOfRecords: response.data.users.total,
      });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleOpenConfirmDelete = (user) => {
    this.setState({
      showConfirmDelete: true,
      actionName: `${user.name} ${user.last_name}`,
      selectedUser: user,
    });
  };

  handleDelete = async () => {
    const { selectedUser } = this.state;
    this.setState({ isDeleting: true });
    try {
      await axios.post(`user/delete/${selectedUser.id}`);
      this.setState({ showConfirmDelete: false });
      this.fetchUsers();
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
      console.log('ERROR', err);
    } finally {
      this.setState({ isDeleting: false });
    }
  }

  handleShowChangePassUserModal = (user) => {
    this.setState({
      showChangePassUserModal: true,
      actionName: `${user.name} ${user.last_name}`,
      selectedUser: user,
    });
  };

  handleCloseChangePassUserModal = () => {
    this.setState({ showChangePassUserModal: false });
  };

  handleChangePassword = async (newPassword) => {
    const { selectedUser } = this.state;
    try {
      await axios.post('user/update-password', {
        user_id: selectedUser.id,
        password: newPassword,
      });
      this.handleCloseChangePassUserModal();
      this.fetchUsers();
    } catch (err) {
      // TODO Show message Error Desconocido
      console.log('ERROR', err);
    }
  }

  changeTableData = (response) => {
    this.setState({
      users: response.data.users.data,
      totalOfPages: response.data.users.last_page
    });
  }

  render() {
    const {
      detailUser,
      editUser,
      currentPage,
      goToNextPage,
      goToPreviousPage,
    } = this.props;
    const {
      isLoading,
      isDeleting,
      users,
      showChangePassUserModal,
      showConfirmDelete,
      actionName,
      totalOfPages,
      showNotification,
      notificationMessage,
      totalOfRecords
    } = this.state;

    return !isLoading ? (
      <>
        <ChangePassUserModal
          onClose={ this.handleCloseChangePassUserModal }
          show={ showChangePassUserModal }
          userName={ actionName }
          onChangePassword={ this.handleChangePassword }
        />
        <ConfirmDeleteModal
          onClose={ () => this.setState({ showConfirmDelete: false }) }
          show={ showConfirmDelete }
          thingToRemoveType="usuario"
          thingToRemoveName={ actionName }
          onConfirm={ this.handleDelete }
        />
        <Row>
          <Col md={ 8 }>
            <SearchInput
              route={ '/user/search' }
              currentPage={ currentPage }
              changeTableData={ this.changeTableData }
            />
          </Col>
          <Col md={ 12 } style={ { padding: "2%" } }>
            { showNotification &&
              <ErrorNotification
                notificationMessage={ notificationMessage }
                closeNotification={ () => this.setState({ showNotification: false }) }
              />
            }
            <Table striped hover >
              <thead>
                <tr>
                  <th>Nombres</th>
                  <th>Apellidos</th>
                  <th>Correo eléctrónico</th>
                  <th>rol</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                { users.map((user) => {
                  const roleAsString = typeof roles[user.role_id] !== 'undefined' ? roles[user.role_id] : '';
                  return (
                    <tr key={ user.id }>
                      <td>{ user.name }</td>
                      <td>{ user.last_name }</td>
                      <td>{ user.email }</td>
                      <td>{ user.rol && user.rol.name }</td>
                      <td>
                        <Button disabled={ isDeleting } bsStyle="info" bsSize="sm" fill title="Ver detalles" onClick={ () => detailUser({ ...user, role: roleAsString }) }><i className="fa fa-search"></i></Button>
                        { user.role_id !== 1 &&
                          <>
                            <Button disabled={ isDeleting } bsStyle="success" bsSize="sm" fill title="Editar" onClick={ () => editUser(user) }><i className="fa fa-pencil"></i></Button>
                            <Button bsStyle="warning" bsSize="sm" fill title="Cambiar contraseña" onClick={ () => this.handleShowChangePassUserModal(user) } ><i className="fa fa-refresh"></i></Button>
                            <Button disabled={ isDeleting } bsStyle="danger" bsSize="sm" fill title="Eliminar" onClick={ () => this.handleOpenConfirmDelete(user) } ><i className="fa fa-trash"></i></Button>
                          </>
                        }
                      </td>
                    </tr>
                  );
                }) }
              </tbody>
            </Table>

            { users.length > 0 && (
              <>
                <Pager>
                  <Pager.Item
                    previous
                    disabled={ currentPage === 1 }
                    onClick={ goToPreviousPage }
                  >
                    <i className="fa fa-chevron-left"></i>  Anterior
                  </Pager.Item>
                  <Pager.Item>
                    { currentPage }/{ totalOfPages }
                  </Pager.Item>
                  <Pager.Item
                    disabled={ currentPage === totalOfPages }
                    next
                    onClick={ goToNextPage }
                  >
                    Siguiente <i className="fa fa-chevron-right"></i>
                  </Pager.Item>
                </Pager>
                <Pager>
                  <Pager.Item>
                    Total: { totalOfRecords } registros
                </Pager.Item>
                </Pager>
              </>
            ) }
          </Col>
        </Row>
      </>
    ) : (
        <Loader
          type="Rings"
          color="#00BFFF"
          height={ 100 }
          width={ 100 }
          style={ { display: 'flex', justifyContent: 'center' } }
        />
      );
  }
}
export default UserList