import React, { Component } from "react";
import { Row, Table, Col, Pager } from "react-bootstrap";
import { generalRoute, gRoute } from "variables/DomainRoutes.jsx";
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import Button from 'components/CustomButton/CustomButton';
import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal.jsx';
import axios from 'components/axios';
import Loader from 'react-loader-spinner'
import { mensajeError500 } from "variables/Variables.jsx";
import ShowAttachedModal from "components/Modal/ShowAttachedModal";
import SearchInputHomeworks from 'components/Search/SearchInputHomeworks';
import ModalExcel from 'components/ModalExcel';

class HomeworkList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      homeworks: [],
      totalOfPages: 0,
      isLoading: false,
      isDeleting: false,
      showNotification: false,
      notificationHomework: '',
      showAttachedModal: false,
      attacheds: '',
      titleAttached: '',
      typeShowModal: '',
      isTeacher: false,
      selectedHomework: null,
      openModal: false
    };
    this.controlModal = this.controlModal.bind(this);
  }

  controlModal(show) {
    this.setState({ openModal: show || false });
  }

  async componentDidMount() {
    const localUser = JSON.parse(localStorage.getItem('USER'))
    this.setState({ isTeacher: localUser.role_id === 3 });
    this.fetchHomeworks();
  }

  fetchHomeworks = async () => {
    const { currentPage } = this.props;
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('homework/list', {
        current_page: currentPage,
      });
      this.setState({
        homeworks: response.data.homeworks.data,
        totalOfPages: response.data.homeworks.last_page
      });
      this.handleExort()
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleOpenConfirmDelete = (homework) => {
    if (homework.user) {
      this.setState({
        showConfirmDelete: true,
        actionName: `del profesor ${homework.user.name} ${homework.user.last_name}` + " de la materia " + homework.course.name,
        selectedHomework: homework,
      });
    } else {
      this.setState({
        showConfirmDelete: true,
        actionName: " de la materia " + homework.course.name,
        selectedHomework: homework,
      });
    }
  };

  handleDelete = async () => {
    const { selectedHomework } = this.state;
    this.setState({ isDeleting: true });
    try {
      await axios.post(`homework/delete/${selectedHomework.id}`);
      this.setState({ showConfirmDelete: false });
      this.fetchHomeworks();
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
      } else {
        console.log('ERROR', err);
      }
    } finally {
      this.setState({ isDeleting: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentPage !== this.props.currentPage) {
      this.fetchHomeworks();
    }
  }
  renderNumber = (homework) => {
    if (homework.url5 !== null && homework.url5 !== "") {
      return 5;
    }
    if (homework.url4 !== null && homework.url4 !== "") {
      return 4;
    }
    if (homework.url3 !== null && homework.url3 !== "") {
      return 3;
    }
    if (homework.url2 !== null && homework.url2 !== "") {
      return 2;
    }
    if (homework.url1 !== null && homework.url1 !== "") {
      return 1;
    }
    return 0
  }

  handleShowModal = (homework, type, num) => {
    if (num > 0) {
      if (type === "urls") {
        this.setState({
          showAttachedModal: true,
          attacheds: homework,
          titleAttached: 'Se adjuntaron las siguientes urls',
          typeShowModal: type
        });
      } else {
        this.setState({
          showAttachedModal: true,
          attacheds: homework,
          titleAttached: 'Se adjuntaron los siguientes archivos',
          typeShowModal: type
        });
      }
    }
  }
  
  changeTableData = (response) => {
    this.setState({
      homeworks: response.data.homeworks.data,
      totalOfPages: response.data.homeworks.last_page
    });
  }

  render() {
    const {
      detailHomework,
      currentPage,
      goToNextPage,
      goToPreviousPage,
    } = this.props;
    const {
      showConfirmDelete,
      actionName,
      isLoading,
      homeworks,
      notificationHomework,
      showNotification,
      totalOfPages,
      showAttachedModal,
      attacheds,
      titleAttached,
      typeShowModal,
      isTeacher,
      urlExcel,
      openModal
    } = this.state;
    return !isLoading ? (
      <>
        <ModalExcel url="/homework/excel" isOpen={openModal} controlModal={this.controlModal} />
        <ConfirmDeleteModal
          onClose={() => this.setState({ showConfirmDelete: false })}
          show={showConfirmDelete}
          thingToRemoveType="tarea"
          prepo=" a la "
          thingToRemoveName={actionName}
          onConfirm={this.handleDelete}
        />
        <Row style={{ marginTop: "2%" }}>
          <Col md={12}>
            {
              isTeacher &&
              <Button
                bsStyle="primary" fill
                style={{ margin: "1%" }}
                onClick={() => this.props.history.replace("/admin/homework/assign")}
              >
                Asignar Tarea
              </Button>
            }
            <Button
              bsStyle="primary"
              pullRight
              style={{
                margin: "1%",
                marginTop: "3%"
              }}
              onClick={this.controlModal}
            >
              Exportar Excel
            </Button>
            {showNotification &&
              <ErrorNotification
                notificationHomework={notificationHomework}
                closeNotification={() => this.setState({ showNotification: false })}
              />
            }
            {
              showAttachedModal &&
              <ShowAttachedModal
                title={titleAttached}
                attacheds={attacheds}
                onClose={() => this.setState({ showAttachedModal: false })}
                show={showAttachedModal}
                route={generalRoute}
                type={typeShowModal}
              />
            }
            <Col md={12}>
              <SearchInputHomeworks
                route={'/homework/search'}
                currentPage={currentPage}
                changeTableData={this.changeTableData}
              />
            </Col>
            <Table striped hover >
              <thead>
                <tr>
                  <th>Urls</th>
                  <th>Archivos</th>
                  <th>nivel</th>
                  <th>grado</th>
                  <th>grupo</th>
                  <th>creado</th>
                  <th>materia</th>
                  <th>profesor</th>
                  <th>acciones</th>
                </tr>
              </thead>
              <tbody>
                {homeworks.map((homework) => {
                  return (
                    <tr key={homework.id}>
                      <td>
                        <span className="label label-info"
                          onClick={() => this.handleShowModal(homework, "urls", this.renderNumber(homework))}
                        >
                          <i className="fa fa-link"></i>
                          {
                            this.renderNumber(homework)
                          }
                        </span>
                      </td>
                      <td>
                        <span className="label label-primary"
                          onClick={() => this.handleShowModal(homework, "files", (homework.files !== null && homework.files !== "") ? homework.files.split(',').length : 0)}>
                          <i className="fa fa-paperclip"></i>{
                            homework.files ? homework.files.split(',').length : 0
                          }
                        </span>
                      </td>
                      <td>{homework.level && homework.level.name}</td>
                      <td>{homework.grade && homework.grade.name}</td>
                      <td>{homework.group && homework.group.name}</td>
                      <td>{homework.created_at}</td>
                      <td>{homework.course && homework.course.name}</td>
                      <td>{homework.user && `${homework.user.name} ${homework.user.last_name}`}</td>
                      <td>
                        <Button bsStyle="info" bsSize="sm" fill title="Ver detalles" onClick={() => detailHomework(homework)} ><i className="fa fa-search"></i></Button>
                        <Button bsStyle="danger" bsSize="sm" fill title="Eliminar" onClick={() => this.handleOpenConfirmDelete(homework)} ><i className="fa fa-trash"></i></Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {homeworks.length > 0 && (
              <Pager>
                <Pager.Item
                  previous
                  disabled={currentPage === 1}
                  onClick={goToPreviousPage}
                >
                  <i className="fa fa-chevron-left"></i>  Anterior
                </Pager.Item>
                <Pager.Item>

                  {currentPage}/{totalOfPages}

                </Pager.Item>
                <Pager.Item
                  disabled={currentPage === totalOfPages}
                  next
                  onClick={goToNextPage}
                >
                  Siguiente <i className="fa fa-chevron-right"></i>
                </Pager.Item>
              </Pager>
            )}
          </Col>
        </Row>
      </>
    ) : (
      <Loader
        type="Rings"
        color="#00BFFF"
        height={100}
        width={100}
        style={{ display: 'flex', justifyContent: 'center' }}
      />
    );
  }
}

export default HomeworkList;
