
import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  Modal,
  ControlLabel
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import styles from './styles'
import imageLogin from "assets/img/part.png";
import logo from "assets/img/logo.png";
import Button from 'components/CustomButton/CustomButton';
import QRCode from 'qrcode.react'
import Loader from 'react-loader-spinner'
import socketIOClient from 'socket.io-client';
import { socketRoute } from "variables/DomainRoutes.jsx";

class LoginParent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      socketId: '',
      showLoadingModal: false,
    };
  }

  componentDidMount() {
    this.getSocketId()
  }

  getSocketId = () => {
    let $this = this;
    const socket = socketIOClient(socketRoute);
    socket.on('connect', function () {
      console.log("connected socket", socket.id)
      $this.setState({ socketId: socket.id });
      socket.on('recive', function (data) {
        console.log(data);
        //var $data = 'isToken: ' + data.isToken + ', TOKEN: ' + data.token;
        if (data.isToken === true) {
          $this.setState({ showLoadingModal: true });
          setTimeout(function () {
            $this.setState({ showLoadingModal: false });
            localStorage.setItem('TOKEN', data.token);
            localStorage.setItem('USER', JSON.stringify(data.user));
            localStorage.setItem('INSTITUTION', JSON.stringify(data.institution));
            $this.props.history.replace({ pathname: '/admin/my-homework/index', showFirstLoginModal: false });
          }, 2000);
        }
      });
    });

  }

  render() {

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={ 5 } style={ { marginTop: "1%" } }>
              <Card
                content={
                  <Row style={ { paddingBottom: "15%" } }>
                    <Modal show={ this.state.showLoadingModal } backdrop="static">
                      <Modal.Header>
                        <Modal.Title>Obteniendo credenciales ... </Modal.Title>
                      </Modal.Header>
                      <Modal.Body style={ { wordWrap: "break-word" } }>
                        <Loader
                          type="Rings"
                          color="#00BFFF"
                          height={ 100 }
                          width={ 100 }
                          style={ { marginLeft: "40%" } }
                        />
                      </Modal.Body>
                    </Modal >
                    <h2 style={ { textAlign: "center" } }>BIENVENIDO A DIFEMESCUELAS</h2>
                    <Col md={ 1 }> </Col>
                    <Col md={ 10 } style={ { textAlign: "center", marginTop: "10%" } }>
                      <img
                        src={ logo }
                        width="200"
                        alt=""
                      />
                      <FormGroup style={ styles.marginTop2 }>
                        <Col md={ 12 }>
                          <ControlLabel> <b> ESCANEE EL SIGUIENTE QR CON SU CELULAR PARA INICIAR SESIÓN </b> </ControlLabel>
                          <Col md={ 12 } style={ { marginTop: "2%" } }>
                            { this.state.socketId !== '' ?
                              < QRCode value={ this.state.socketId } id="canvasQR" size={ 256 } />
                              : (
                                <Loader
                                  type="Rings"
                                  color="#00BFFF"
                                  height={ 100 }
                                  width={ 100 }
                                />
                              )
                            }
                          </Col>
                        </Col>
                      </FormGroup>
                      <Row>
                        <Col md={ 2 }></Col>
                        <Col md={ 8 }>
                          <Button bsStyle="info" fill block style={ { marginTop: "10%" } } onClick={ () => this.props.history.replace('/login') }>
                            Regresar al login de director
                          </Button>
                        </Col>
                        <Col md={ 2 }></Col>
                      </Row>
                    </Col>
                    <Col md={ 1 }></Col>
                  </Row>
                }
              />
            </Col>
            <Col md={ 7 } style={ { padding: "0px" } }>
              <img
                src={ imageLogin }
                width="100%"
                height="945"
                alt=""
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default LoginParent;
