import React from 'react'

import { Grid, Row, Col } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import UserList from 'views/users/UserList';
import UserDetail from 'views/users/UserDetail';
import UserEdit from 'views/users/UserEdit';
import UserForm from './UserForm';
import styles from './styles';
import { Card } from "components/Card/Card.jsx";

class UserMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0,
      activeUser: {},
      currentState: 1,
      currentPage: 1,
    };
  }
  handleBackToList = () => {
    this.setState({ key: 0 });
  };
  handleDetailUser = (userData) => {
    this.setState({ key: 2, activeUser: userData });
  };

  handleEditUser = (userData) => {
    this.setState({ key: 3, activeUser: userData });
  };

  goToNextPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage + 1 }));
  }

  goToPreviewsPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage - 1 }));
  }

  render() {
    const {
      key,
      activeUser,
      currentPage,
    } = this.state;

    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <Grid fluid>
              { key === 0 && (
                <Row>
                  <Col md={ 6 } style={ { paddingLeft: "2%" } }>
                    <Button bsStyle="primary" fill style={ styles.topButton } onClick={ () => this.setState({ key: 1 }) }>
                      Nuevo Usuario
                  </Button>
                  </Col>
                  <Col md={ 6 }>
                    <Button disabled bsStyle="primary" pullRight style={ styles.topButton, { "display": "none" } }>Exportar Excel</Button>
                  </Col>
                </Row>
              ) }
              {
                {
                  0: (
                    <UserList
                      currentPage={ currentPage }
                      goToNextPage={ this.goToNextPage }
                      goToPreviousPage={ this.goToPreviewsPage }
                      detailUser={ this.handleDetailUser }
                      editUser={ this.handleEditUser }
                    />
                  ),
                  1: <UserForm returnToList={ this.handleBackToList } />,
                  2: <UserDetail returnToList={ this.handleBackToList } userData={ activeUser } />,
                  3: <UserEdit returnToList={ this.handleBackToList } userData={ activeUser } />,
                }[key]
              }
            </Grid>
          }
        />
      </div>
    );
  }
}
export default UserMenu