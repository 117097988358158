import React from 'react'
import { Grid } from "react-bootstrap";
import ScannerList from 'views/attendance/ScannerList';
import ScannerEdit from 'views/attendance/ScannerEdit';
import ScannerDetail from 'views/attendance/ScannerDetail';
import { Card } from "components/Card/Card.jsx";

class Scanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0,
      scannerData: {},
      currentPage: 1,
    };
  }

  handleBackToList = () => {
    this.setState({ key: 0 });
  };
  handleEditScanner = (scannerData) => {
    this.setState({ key: 1, scannerData: scannerData });
  };
  handleDetailScanner = (scannerData) => {
    this.setState({ key: 2, scannerData: scannerData });
  };

  goToNextPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage + 1 }));
  }

  goToPreviousPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage - 1 }));
  }

  render() {
    const {
      key,
      scannerData,
      currentPage
    } = this.state;

    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <Grid fluid>
              {
                {
                  0: <ScannerList
                    editScanner={ this.handleEditScanner }
                    detailScanner={ this.handleDetailScanner }
                    currentPage={ currentPage }
                    goToNextPage={ this.goToNextPage }
                    goToPreviousPage={ this.goToPreviewsPage }
                  />,
                  1: <ScannerEdit returnToList={ this.handleBackToList } scannerData={ scannerData } />,
                  2: <ScannerDetail returnToList={ this.handleBackToList } scannerData={ scannerData } />
                }[key]
              }
            </Grid>
          }
        />
      </div>
    );
  }
}
export default Scanner