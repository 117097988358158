import React, { Component } from "react";
import {
  Row, Col, ControlLabel
} from "react-bootstrap";
import { mensajeError500 } from "variables/Variables.jsx";
import { Card } from "components/Card/Card.jsx";
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import axios from 'components/axios';
import Loader from 'react-loader-spinner'
import Select from 'react-select'
import Button from 'components/CustomButton/CustomButton';

class TeachingCourseEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      levelId: null,
      levels: [],
      gradeId: null,
      grades: [],
      groupId: null,
      groups: [],
      courseId: [],
      courses: [],
      userId: 124,
      users: [],
      isSubmitting: false,
      isLoadingCourses: false
    };
  }
  async componentDidMount() {
    this.fetchInfo();
  }

  fetchInfo = async () => {
    const { teachingCourseData: { level, grade, group, course, user, id } } = this.props;
    this.setState({
      id: id,
      levelId: level ? level.id : '',
      gradeId: grade ? grade.id : '',
      groupId: group ? group.id : '',
      courseId: course ? course.id : '',
      userId: user ? user.id : '',
    });
    this.fetchTeachers();
    this.fetchCourses();
    this.fetchLevels();
    this.fetchGrades(level ? level.id : 0, true);
    this.fetchGroups(grade ? grade.id : 0, true);
  }

  fetchLevels = async () => {
    this.setState({ loadSelect: 1 });
    try {
      const response = await axios.post('/level/list');
      const newData = response.data.Levels.data.map((item) => {
        return { value: item.id, label: item.name }
      })
      this.setState({ levels: newData })
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ loadSelect: 0 });
    }
  }

  fetchGrades = async (levelId, isIni) => {
    this.setState({ loadSelect: 2 });
    try {
      const response = await axios.post('/grade/by/level', { level_id: levelId });
      const newData = response.data.Grades.map((item) => {
        return { value: item.id, label: item.name }
      })
      this.setState({ grades: newData })
    } catch (err) {
      this.setState({ grades: [] })
      console.log('ERROR', err);
    } finally {
      if (isIni) {
        this.setState({ loadSelect: 0 });
      } else {
        this.setState({ loadSelect: 0, gradeId: '' });
      }
    }
  }

  fetchGroups = async (isIni) => {
    this.setState({ loadSelect: 3 });
    try {
      const response = await axios.post('group/full_list');
      const newData = response.data.Groups.map((item) => {
        return { value: item.id, label: item.name }
      })
      this.setState({ groups: newData })
    } catch (err) {
      this.setState({ groups: [] })
      console.log('ERROR', err);
    } finally {
      if (isIni) {
        this.setState({ loadSelect: 0 });
      } else {
        this.setState({ loadSelect: 0, groupId: '' });
      }
    }
  }

  fetchCourses = async () => {
    this.setState({ isLoadingCourses: true });
    try {
      const response = await axios.post('course/list_select');
      this.setState({ courses: response.data.courses })
      console.log(response.data.courses);
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoadingCourses: false });
    }
  }
  fetchTeachers = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('/user/teacher/list_select');
      this.setState({ users: response.data.users_select })
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const {
      courseId,
      levelId,
      gradeId,
      groupId,
      userId,
      id
    } = this.state;
    if (courseId === '') {
      this.setState({
        showNotification: true,
        notificationMessage: "Debe seleccionar una opción para el campo MATERIA."
      });
      return;
    }
    if (levelId === null) {
      this.setState({
        showNotification: true,
        notificationMessage: "Debe seleccionar una opción para el campo NIVEL. "
      });
      return;
    }
    if (gradeId === null) {
      this.setState({
        showNotification: true,
        notificationMessage: "Debe seleccionar una opción para el campo GRADO. "
      });
      return;
    }
    if (groupId === null) {
      this.setState({
        showNotification: true,
        notificationMessage: "Debe seleccionar una opción para el campo GRUPO. "
      });
      return;
    }
    if (userId === null) {
      this.setState({
        showNotification: true,
        notificationMessage: "Debe seleccionar una opción para el campo PROFESOR. "
      });
      return;
    }
    this.setState({ isSubmitting: true });
    try {
      await axios.post('teaching_course/edit/' + id, {
        course_id: courseId,
        level_id: levelId,
        grade_id: gradeId,
        group_id: groupId,
        user_id: userId
      });
      this.props.returnToList();
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
    } finally {
      this.setState({ isSubmitting: false });
    }
  }

  getIdValue = (options, match) => {
    const filterResult = options.filter(({ value }) => value === match);
    if (filterResult.length === 0) {
      return []
    }
    return filterResult
  }

  render() {
    const {
      showNotification,
      notificationMessage,
      isLoading,
      courses,
      users,
      levels,
      grades,
      groups,
      loadSelect,
      levelId,
      gradeId,
      groupId,
      courseId,
      userId
    } = this.state
    const { returnToList } = this.props;
    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <form onSubmit={ this.handleSubmit }>
              <Row style={ { paddingLeft: "30px" } }>
                <h4>Asignar Materia</h4>
                { showNotification &&
                  <ErrorNotification
                    notificationMessage={ notificationMessage }
                    closeNotification={ () => this.setState({ showNotification: false }) }
                  />
                }
                <Row>
                  <Col md={ 11 } >
                    <Col md={ 4 } >
                      <ControlLabel > PROFESOR </ControlLabel>
                      { !isLoading ?
                        <Select options={ users }
                          name="courseId" className="basic-single"
                          classNamePrefix="select"
                          onChange={ (e) => this.setState({ 'userId': e.value }) }
                          placeholder="Seleccione un profesor"
                          value={ users && this.getIdValue(users, userId) }
                        />
                        :
                        <Loader
                          type="Rings"
                          color="#00BFFF"
                          height={ 30 }
                          width={ 30 }
                          style={ { display: 'flex', justifyContent: 'center' } }
                        />
                      }
                    </Col>
                    <Col md={ 4 } >
                      <ControlLabel > MATERIA </ControlLabel>
                      { !isLoading ?
                        <Select options={ courses }
                          name="courseId" className="basic-single"
                          classNamePrefix="select"
                          onChange={ (e) => this.setState({ 'courseId': e.value }) }
                          placeholder="Seleccione una materia"
                          style={ { width: "30%" } }
                          value={ courses && this.getIdValue(courses, courseId) }
                        />
                        :
                        <Loader
                          type="Rings"
                          color="#00BFFF"
                          height={ 30 }
                          width={ 30 }
                          style={ { display: 'flex', justifyContent: 'center' } }
                        />
                      }
                    </Col>
                  </Col>
                  <Col md={ 11 }>
                    <Col md={ 4 } >
                      <ControlLabel > NIVEL </ControlLabel>
                      { loadSelect === 1 ?
                        <Loader
                          type="Rings"
                          color="#00BFFF"
                          height={ 30 }
                          width={ 30 }
                          style={ { display: 'flex', justifyContent: 'center' } }
                        />
                        :
                        <Select options={ levels }
                          name="levelId" className="basic-single"
                          classNamePrefix="select"
                          onChange={ (e) => { this.setState({ 'levelId': e.value }); this.fetchGrades(e.value, false) } }
                          placeholder="Seleccione un nivel"
                          value={ levels && this.getIdValue(levels, levelId) }
                        />
                      }
                    </Col>
                    <Col md={ 4 } >
                      <ControlLabel > GRADO </ControlLabel>
                      { loadSelect === 2 ?
                        <Loader
                          type="Rings"
                          color="#00BFFF"
                          height={ 30 }
                          width={ 30 }
                          style={ { display: 'flex', justifyContent: 'center' } }
                        />
                        :
                        <Select options={ grades }
                          name="gradeId" className="basic-single"
                          classNamePrefix="select"
                          onChange={ (e) => { this.setState({ 'gradeId': e.value }); this.fetchGroups(e.value, false) } }
                          placeholder="Seleccione una opción"
                          value={ grades && this.getIdValue(grades, gradeId) }
                          isDisabled={ grades && grades.length === 0 }
                        />
                      }
                    </Col>
                    <Col md={ 4 } >
                      <ControlLabel > GRUPO </ControlLabel>
                      { loadSelect === 3 ?
                        <Loader
                          type="Rings"
                          color="#00BFFF"
                          height={ 30 }
                          width={ 30 }
                          style={ { display: 'flex', justifyContent: 'center' } }
                        />
                        :
                        <Select options={ groups }
                          name="groupId" className="basic-single"
                          classNamePrefix="select"
                          onChange={ (e) => this.setState({ 'groupId': e.value }) }
                          placeholder="Seleccione una opción"
                          value={ groups && this.getIdValue(groups, groupId) }
                          isDisabled={ groups && groups.length === 0 }
                        />
                      }
                    </Col>
                  </Col>
                  <Col md={ 12 } >
                    <Button bsStyle="success" pullRight fill type="submit"
                      style={ { marginRight: "2%", marginTop: "2%" } } >
                      <i className="fa fa-save"></i> Guardar cambios
                    </Button>
                    <Button bsStyle="danger" pullRight fill type="button"
                      style={ { marginRight: "2%", marginTop: "2%" } }
                      onClick={ returnToList }>
                      <i className="fa fa-chevron-left"></i> Regresar
                    </Button>
                  </Col>
                </Row>
              </Row>
            </form>
          }
        />
      </div>
    );
  }
}

export default TeachingCourseEdit;
