import React from 'react'
import { Modal, Row, Col } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';

const FirstLoginModal = ({ onClose, open, history }) => {
  return (
    <Modal show={open} backdrop="static">
      <Modal.Header>
        <Modal.Title>Primer inicio de sesión</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12} style={{ marginLeft: "5%", marginTop: "2%" }}>
            <h5>Bienvenido a la plataforma web de DIFEMESCUELAS !!</h5>
            Te invitamos a que cambies tu contraseña.
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle="danger" fill onClick={onClose}>  Cancelar </Button>
        <Button bsStyle="info" fill onClick={() => {
          onClose();
          history.replace('/admin/conf/pass');
        }}>  Cambiar contraseña </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default FirstLoginModal;