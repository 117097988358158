import React from 'react'
import {
  Row,
  Col,
} from "react-bootstrap";
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import { mensajeError500 } from "variables/Variables.jsx";
import { generalRoute, uploadRoute } from "variables/DomainRoutes.jsx";
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import Button from 'components/CustomButton/CustomButton';
import axios from 'components/axios';
import styles from './styles'
import Loader from 'react-loader-spinner'
import Select from 'react-select'
import avatar from "assets/img/default-avatar.png";
import LoadingModal from "components/Modal/LoadingModal";

class StudentEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      lastname: '',
      code: '',
      gender: '',
      level1: '',
      level2: '',
      level3: '',
      tutorId: '',
      contact1: '',
      contact2: '',
      phone1: '',
      phone2: '',
      allergies: '',
      bloodType: '',
      photo: '',
      showNotification: false,
      notificationMessage: '',
      parents: [],
      loadSelect: 0,
      showLoadingModal: false
    };
    this.genderOptions = [
      { value: false, label: "Masculino" },
      { value: true, label: "Femenino" }
    ]
  }

  onChangePhoto = async (e) => {
    let filename;
    this.setState({ showLoadingModal: true })
    try {
      const formData = new FormData();
      formData.append('general_file', e.target.files[0])
      formData.append('type', 1);
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
      filename = await axios.post(uploadRoute + 'general/upload', formData, config);
      filename = filename.data.filename
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ photo: filename, showLoadingModal: false })
    }
  }

  async componentDidMount() {
    const { studentData: {
      name, last_name, code, gender, level, grade, group, tutor_id,
      contact1, phone1, contact2, phone2, allergies, blood_type, photo
    } } = this.props;
    this.setState({
      name,
      lastname: last_name,
      code,
      gender,
      level1: level ? level.id : '',
      level2: grade ? grade.id : '',
      level3: group ? group.id : '',
      tutorId: tutor_id,
      contact1,
      phone1,
      contact2,
      phone2,
      allergies,
      bloodType: blood_type,
      photo
    })
    this.fetchParentsId();
    this.fetchLevels();
    this.fetchGrades(level ? level.id : 0, true);
    this.fetchGroups(grade ? grade.id : 0, true);
  }

  fetchLevels = async () => {
    this.setState({ loadSelect: 1 });
    try {
      const response = await axios.post('/level/list');
      const newData = response.data.Levels.data.map((item) => {
        return { value: item.id, label: item.name }
      })
      this.setState({ levels: newData })
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ loadSelect: 0 });
    }
  }
  fetchGrades = async (level1, isIni) => {
    this.setState({ loadSelect: 2 });
    try {
      const response = await axios.post('/grade/by/level', { level_id: level1 });
      const newData = response.data.Grades.map((item) => {
        return { value: item.id, label: item.name }
      })
      this.setState({ grades: newData })
    } catch (err) {
      this.setState({ grades: [] })
      console.log('ERROR', err);
    } finally {
      if (isIni) {
        this.setState({ loadSelect: 0 });
      } else {
        this.setState({ loadSelect: 0, level2: '' });
      }
    }
  }

  fetchGroups = async (isIni) => {
    this.setState({ loadSelect: 3 });
    try {
      const response = await axios.post('group/full_list');
      const newData = response.data.Groups.map((item) => {
        return { value: item.id, label: item.name }
      })
      this.setState({ groups: newData })
    } catch (err) {
      this.setState({ groups: [] })
      console.log('ERROR', err);
    } finally {
      if (isIni) {
        this.setState({ loadSelect: 0 });
      } else {
        this.setState({ loadSelect: 0, level3: '' });
      }
    }
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  fetchParentsId = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('/tutor/list_select');
      const newData = response.data.tutors.map((item) => {
        return { value: item.id, label: `${item.name} ${item.last_name}` }
      })
      this.setState({ parents: newData })
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }


  handleSubmit = async (e) => {
    e.preventDefault();
    const { studentData: { id } } = this.props;
    const {
      name,
      lastname,
      code,
      gender,
      level1,
      level2,
      level3,
      tutorId,
      contact1,
      contact2,
      phone1,
      phone2,
      bloodType,
      allergies,
      photo
    } = this.state;
    if (gender === '') {
      this.setState({
        showNotification: true,
        notificationMessage: "Debe seleccionar una opción para el campo SEXO"
      });
      return;
    }
    if (tutorId === '') {
      this.setState({
        showNotification: true,
        notificationMessage: "Debe seleccionar una opción para el campo PADRE"
      });
      return;
    }
    if (level1 === '') {
      this.setState({
        showNotification: true,
        notificationMessage: "Debe seleccionar una opción para el campo NIVEL"
      });
      return;
    }
    if (level2 === '') {
      this.setState({
        showNotification: true,
        notificationMessage: "Debe seleccionar una opción para el campo GRADO"
      });
      return;
    }
    if (level3 === '') {
      this.setState({
        showNotification: true,
        notificationMessage: "Debe seleccionar una opción para el campo GRUPO"
      });
      return;
    }
    this.setState({ isSubmitting: true });
    try {
      await axios.post(`/student/edit/${id}`, {
        name,
        last_name: lastname,
        code,
        gender,
        level1,
        level2,
        level3,
        tutor_id: tutorId,
        contact1,
        contact2,
        phone1,
        phone2,
        blood_type: bloodType,
        allergies,
        photo
      });
      this.props.returnToList(this.props.currentPage);
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
      }
      console.log('ERROR', err);
    } finally {
      this.setState({ isSubmitting: false });
    }
  }

  getIdValue = (options, match) => {
    const filterResult = options.filter(({ value }) => value === match);
    if (filterResult.length === 0) {
      return []
    }
    return filterResult
  }

  render() {
    const {
      returnToList,
    } = this.props;
    const {
      name,
      lastname,
      code,
      gender,
      level1,
      level2,
      level3,
      levels,
      grades,
      groups,
      showNotification,
      notificationMessage,
      isLoading,
      parents,
      tutorId,
      loadSelect,
      contact1,
      contact2,
      phone1,
      phone2,
      bloodType,
      allergies,
      photo,
      showLoadingModal
    } = this.state;

    return (
      <>
        <form onSubmit={ this.handleSubmit }>
          <LoadingModal
            show={ showLoadingModal }
            text="Cargando imagen ..."
          />
          <Row>
            <Col md={ 12 } style={ { padding: "2%" } }>
              <h4>Editar un estudiante</h4>
              { showNotification &&
                <ErrorNotification
                  notificationMessage={ notificationMessage }
                  closeNotification={ () => this.setState({ showNotification: false }) }
                />
              }
              <Row style={ { marginTop: "2%" } }>
                <Col md={ 4 }>
                  <FormGroup>
                    <ControlLabel> NOMBRES </ControlLabel>
                    <FormControl type="text" name="name" value={ name } onChange={ e => this.onChange(e) } required />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel> APELLIDOS </ControlLabel>
                    <FormControl type="text" name="lastname" value={ lastname } onChange={ e => this.onChange(e) } required />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel> MATRICULA </ControlLabel>
                    <FormControl type="text" name="code" value={ code } onChange={ e => this.onChange(e) } required />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel> SEXO </ControlLabel>
                    { typeof (gender) === "boolean" &&
                      <Select
                        options={ this.genderOptions }
                        name="gender" className="basic-single"
                        isSearchable={ false }
                        value={ this.getIdValue(this.genderOptions, gender) }
                        classNamePrefix="select" onChange={ (e) => this.setState({ 'gender': e.value }) } />
                    }
                  </FormGroup>
                </Col>
                <Col md={ 2 }></Col>
                <Col md={ 4 }>
                  <FormGroup>
                    <ControlLabel> PADRE </ControlLabel>
                    { !isLoading ?
                      <Select options={ parents }
                        name="tutorId" className="basic-single"
                        classNamePrefix="select"
                        onChange={ (e) => this.setState({ 'tutorId': e.value }) }
                        value={ parents && this.getIdValue(parents, tutorId) }
                      />
                      :
                      <Loader
                        type="Rings"
                        color="#00BFFF"
                        height={ 30 }
                        width={ 30 }
                        style={ { display: 'flex', justifyContent: 'center' } }
                      />
                    }

                  </FormGroup>
                  <FormGroup>
                    <ControlLabel> NIVEL </ControlLabel>
                    { loadSelect === 1 ?
                      <Loader
                        type="Rings"
                        color="#00BFFF"
                        height={ 30 }
                        width={ 30 }
                        style={ { display: 'flex', justifyContent: 'center' } }
                      />
                      :
                      <Select options={ levels }
                        name="levelId" className="basic-single"
                        classNamePrefix="select"
                        onChange={ (e) => { this.setState({ 'level1': e.value }); this.fetchGrades(e.value, false) } }
                        placeholder="Seleccione un nivel"
                        value={ levels && this.getIdValue(levels, level1) }
                      />
                    }
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel> GRADO </ControlLabel>
                    { loadSelect === 2 ?
                      <Loader
                        type="Rings"
                        color="#00BFFF"
                        height={ 30 }
                        width={ 30 }
                        style={ { display: 'flex', justifyContent: 'center' } }
                      />
                      :
                      <Select options={ grades }
                        name="level2" className="basic-single"
                        classNamePrefix="select"
                        onChange={ (e) => { this.setState({ 'level2': e.value }); this.fetchGroups(e.value, false) } }
                        placeholder="Seleccione una opción"
                        value={ grades && this.getIdValue(grades, level2) }
                        isDisabled={ grades && grades.length === 0 }
                      />
                    }
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel> GRUPO </ControlLabel>
                    { loadSelect === 3 ?
                      <Loader
                        type="Rings"
                        color="#00BFFF"
                        height={ 30 }
                        width={ 30 }
                        style={ { display: 'flex', justifyContent: 'center' } }
                      />
                      :
                      <Select options={ groups }
                        name="level3" className="basic-single"
                        classNamePrefix="select"
                        onChange={ (e) => this.setState({ 'level3': e.value }) }
                        placeholder="Seleccione una opción"
                        value={ groups && this.getIdValue(groups, level3) }
                        isDisabled={ groups && groups.length === 0 }
                      />
                    }
                  </FormGroup>
                </Col>
                <Col md={ 2 }></Col>
              </Row>
              <Row>
                <h4>Editar datos adicionales</h4>
                <Col md={ 4 }>
                  <FormGroup>
                    <ControlLabel> CONTACTO DE EMERGENCIA 1 </ControlLabel>
                    <FormControl type="text" name="contact1" value={ contact1 } onChange={ e => this.onChange(e) } />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel> TELÉFONO 1 </ControlLabel>
                    <FormControl type="text" name="phone1" value={ phone1 } onChange={ e => this.onChange(e) } />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel> CONTACTO DE EMERGENCIA 2 </ControlLabel>
                    <FormControl type="text" name="contact2" value={ contact2 } onChange={ e => this.onChange(e) } />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel> TELÉFONO 2 </ControlLabel>
                    <FormControl type="text" name="phone2" value={ phone2 } onChange={ e => this.onChange(e) } />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel> TIPO DE SANGRE</ControlLabel>
                    <FormControl type="text" name="bloodType" value={ bloodType } onChange={ e => this.onChange(e) } />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel> ALERGIAS </ControlLabel>
                    <FormControl type="text" name="allergies" value={ allergies } onChange={ e => this.onChange(e) } />
                  </FormGroup>
                </Col>
                <Col md={ 2 }></Col>
                <Col md={ 6 }>
                  <Col md={ 12 }>
                    <img src={
                      photo !== '' ?
                        generalRoute + photo : avatar
                    }
                      alt="foto-de-estudiante"
                      className="img-circle"
                      style={ { width: "50%", marginTop: "15%" } } />
                  </Col>
                  <Col md={ 8 }>
                    <ControlLabel> CAMBIO DE FOTO DEL ESTUDIANTE </ControlLabel>
                    <FormControl
                      type="file"
                      onChange={ e => this.onChangePhoto(e) }
                      accept="image/*"
                    />
                    <span><b>Nota:</b> La foto no puede superar 1MB de tamaño.</span>
                  </Col>
                </Col>
              </Row>
              <Row>
                <Col md={ 12 } style={ { marginLeft: "0px" } }>
                  <Button bsStyle="success" pullRight fill type="submit" style={ styles.saveButton }>
                    <i className="fa fa-save"></i> Guardar
                </Button>
                  <Button bsStyle="danger" pullRight fill type="button" onClick={ () => returnToList(this.props.currentPage) } style={ styles.saveButton }>
                    <i className="fa fa-chevron-left"></i> Regresar
                </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </>
    );
  }
}
export default StudentEdit