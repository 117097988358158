

import React from 'react'
import {
  Row,
  Col,
} from "react-bootstrap";
import { FormGroup, ControlLabel, FormControl, Alert } from "react-bootstrap";
import { mensajeError500 } from "variables/Variables.jsx"
import Button from 'components/CustomButton/CustomButton';
import ModalNewRole from 'views/roles/modalNewRole';
import styles from './styles'
import axios from 'components/axios';
import Loader from 'react-loader-spinner'

class UserEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      lastname: '',
      roleId: '',
      email: '',
      seePassword: false,
      isSubmitting: false,
      showNotification: false,
      notificationMessage: '',
      showNewRoleModal: false,
      roles: []
    };
  }

  componentDidMount() {
    const { userData: { name, last_name, role_id, email } } = this.props;
    this.setState({
      name,
      lastname: last_name,
      roleId: role_id,
      email,
    })
    this.fetchRoles();
  }

  fetchRoles = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('/rol/select/list');
      this.setState({
        roles: response.data.roles
      });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  handleShowNewRoleModal = () => {
    this.setState({ showNewRoleModal: true });
  };

  handleCloseNewRoleModal = () => {
    this.setState({ showNewRoleModal: false });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { userData: { id, institution_id } } = this.props;
    const {
      name,
      lastname,
      roleId,
      email,
    } = this.state;

    this.setState({ isSubmitting: true });
    try {
      await axios.post(`user/edit/${id}`, {
        name: name,
        last_name: lastname,
        role_id: roleId,
        institution_id,
        email,
      });
      this.props.returnToList();
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
    } finally {
      this.setState({ isSubmitting: false });
    }
  }

  render() {
    const {
      name,
      lastname,
      roleId,
      email,
      isSubmitting,
      showNotification,
      notificationMessage,
      showNewRoleModal,
      isLoading,
      roles
    } = this.state;
    const { returnToList } = this.props;
    return !isSubmitting ? (
      <form onSubmit={ this.handleSubmit }>
        <ModalNewRole
          onClose={ this.handleCloseNewRoleModal }
          open={ showNewRoleModal }
          onUserForm={ true }
          reloadRoles={ () => this.fetchRoles() }
        />
        <Row>
          <Col md={ 12 } style={ { padding: "2%" } }>
            <h4>Editar un usuario</h4>
            { showNotification && (
              <Alert bsStyle="danger">
                <button type="button" aria-hidden="true" className="close" onClick={ () => this.setState({ showNotification: false }) }>
                  &#x2715;
                </button>
                <span>{ notificationMessage }</span>
              </Alert>
            ) }
            <Row >
              <Col md={ 6 }>
                <Col md={ 10 }>
                  <FormGroup>
                    <ControlLabel> NOMBRES </ControlLabel>
                    <FormControl required type="text" name="name" onChange={ e => this.onChange(e) } value={ name } />
                  </FormGroup>
                </Col>
                <Col md={ 2 }></Col>
                <Col md={ 10 }>
                  <FormGroup>
                    <ControlLabel> APELLIDOS </ControlLabel>
                    <FormControl required type="text" name="lastname" onChange={ e => this.onChange(e) } value={ lastname } />
                  </FormGroup>
                </Col>
                <Col md={ 2 }></Col>
                <Col md={ 10 }>
                  <FormGroup >
                    <ControlLabel>ROL</ControlLabel>
                    { !isLoading ? (
                      <FormControl required componentClass="select" name="roleId" value={ roleId } onChange={ e => this.onChange(e) }>
                        <option value="">Seleccione una opción</option>
                        { roles.map((item) =>
                          <option key={ item.id } value={ item.id }>{ item.name }</option>
                        ) }
                      </FormControl>
                    ) : (
                        <Loader
                          type="Rings"
                          color="#00BFFF"
                          height={ 30 }
                          width={ 30 }
                          style={ { display: 'flex', justifyContent: 'center' } }
                        />
                      ) }
                  </FormGroup>
                </Col>
                <Col md={ 2 }>
                  <Button
                    bsStyle="primary"
                    fill
                    style={ styles.newRoleButton }
                    onClick={ this.handleShowNewRoleModal }
                  >
                    Nuevo Rol</Button>
                </Col>
              </Col>

              <Col md={ 6 }>
                <Col md={ 10 }>
                  <FormGroup>
                    <ControlLabel> CORREO ELECTRÓNICO </ControlLabel> <br />
                    <span>{ email }</span>
                  </FormGroup>
                </Col>
                <Col md={ 2 }></Col>
              </Col>
            </Row>
            <Row>
              <Col md={ 12 } style={ { marginLeft: "0px" } }>
                <Button bsStyle="success" pullRight fill type="submit" style={ styles.saveButton }>
                  <i className="fa fa-save"></i> Guardar
                                </Button>
                <Button bsStyle="danger" pullRight fill type="button" onClick={ returnToList } style={ styles.saveButton }>
                  <i className="fa fa-chevron-left"></i> Regresar
                                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    ) : (
        <Loader
          type="Rings"
          color="#00BFFF"
          height={ 100 }
          width={ 100 }
          style={ { display: 'flex', justifyContent: 'center' } }
        />
      );
  }
}
export default UserEdit