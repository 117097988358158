import React, { Component } from "react";
import { Row, Col, Form, FormControl, FormGroup, ControlLabel } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import styles from "./styles";
import ModalNotification from 'components/Notifications/ModalNotification.jsx'
import { mensajeError500 } from "variables/Variables.jsx";
import { gRoute } from "variables/DomainRoutes.jsx";
import axios from 'components/axios';
import Loader from 'react-loader-spinner'
import ModalExcel from 'components/ModalExcel';

class HeaderMessageTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: '',
      end: '',
      question: '',
      showNotification: false,
      notificationMessage: '',
      notificationType: '',
      isLoading: false,
      urlExcel: '',
      openModal: false
    };
    this.controlModal = this.controlModal.bind(this);
  }

  controlModal(show) {
    this.setState({ openModal: show || false });
  }
  
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  doSearch = async () => {
    const { question, start, end } = this.state;
    const { route, currentPage, changeTableData, isHistory } = this.props;

    if (start == '') {
      this.setState({
        showNotification: true,
        notificationType: "danger",
        notificationMessage: "Para buscar es necesario elegir una fecha de inicio."
      });
      return;
    }
    if (end == '') {
      this.setState({
        showNotification: true,
        notificationType: "danger",
        notificationMessage: "Para buscar es necesario elegir una fecha de fin."
      });
      return;
    }
    this.setState({ isLoading: true });
    try {
      let response;
      if (isHistory) {
        response = await axios.post(route, {
          current_page: currentPage,
          message: question,
          from: start + " 00:00:00",
          to: end + " 23:59:59"
        });
      } else {
        response = await axios.post(route, {
          current_page: currentPage,
          subject: question,
          from: start + " 00:00:00",
          to: end + " 23:59:59"
        });
      }
      if (response.status === 200) {
        this.setState({
          isSuccess: true,
          showNotification: true,
          notificationType: "info",
          notificationMessage: "Búsqueda finalizada."
        });
      }
      changeTableData(response);
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
      } else {
        this.setState({
          showNotification: true,
          notificationType: "danger",
          notificationMessage: mensajeError500
        });
      }
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const {
      showNotification,
      notificationType,
      notificationMessage,
      isLoading,
      question,
      urlExcel,
      loading,
      openModal
    } = this.state;
    const {
      allowMassiveDelete,
      deleteDisabled,
      deleteMasive,
      isHistory,
      excelRoute
    } = this.props;
    return (
      <Row>
        <ModalExcel url={excelRoute} isOpen={openModal} controlModal={this.controlModal} />
        <Col md={8} style={{ paddingLeft: "2%" }}>
          <Form inline>
            <FormGroup style={{ width: "50%" }}>
              <ControlLabel style={{ marginRight: "5%" }}> Desde </ControlLabel>
              <FormControl type="date" name="start" onChange={e => this.onChange(e)} />
            </FormGroup>
            <FormGroup style={{ width: "50%" }} >
              <ControlLabel style={{ marginRight: "5%" }}> Hasta </ControlLabel>
              <FormControl type="date" name="end" onChange={e => this.onChange(e)} />
            </FormGroup>
            <FormGroup style={{ width: "95%" }}>
              <Col md={6} style={{ paddingLeft: "0px" }}>
                <FormControl type="text" name="question" placeholder="Buscar" style={{ width: "100%" }} onChange={e => this.onChange(e)} />
              </Col>
              <Col md={3}>
                <Button
                  bsStyle="success"
                  fill
                  block
                  onClick={() => this.doSearch()}
                >Filtrar</Button>
              </Col>
              <Col md={3}>
                {isLoading &&
                  <>
                    <Col md={4}>
                      <Loader
                        type="Rings"
                        color="#00BFFF"
                        height={40}
                        width={40}
                        style={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}
                      />
                    </Col>
                    <Col md={6}
                      style={{ display: 'flex', marginTop: '5%' }}
                    >Buscando</Col>
                  </>
                }
              </Col>
            </FormGroup>
          </Form>
        </Col>
        <Col md={4}>
          <Button
            bsStyle="primary"
            pullRight
            style={styles.topButton}
            onClick={() => this.controlModal(true)}
          >
            Exportar Excel</Button>
          {(allowMassiveDelete === "true" &&
            <Button
              disabled={deleteDisabled}
              bsStyle="primary"
              pullRight
              style={styles.topButton}
              onClick={() => deleteMasive()}>
              Eliminar Seleccionados</Button>)}
        </Col>
        <Col md={12}>
          {showNotification &&
            <ModalNotification
              notificationMessage={notificationMessage}
              closeNotification={() => this.setState({ showNotification: false })}
              type={notificationType}
            />
          }
        </Col>
      </Row >
    );
  }
}

export default HeaderMessageTable;
