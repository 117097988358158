import React, { Component } from "react";
import { Row, Table, Col } from "react-bootstrap";
import { mensajeError500, monitorStickersFolder } from "variables/Variables.jsx";
import { gRoute } from "variables/DomainRoutes.jsx";
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import Button from 'components/CustomButton/CustomButton';
import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal.jsx';
import axios from 'components/axios';
import Loader from 'react-loader-spinner'
import styles from './styles'

class AttributeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isDeleting: false,
      showConfirmDelete: false,
      actionName: "",
      selectedAttribute: {},
      attributes: [],
      totalOfPages: 0,
      showNotification: false,
      notificationMessage: '',
    };
  }
  async componentDidMount() {
    this.fetchAttributes();
  }

  fetchAttributes = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('attribute/list');
      this.setState({ attributes: response.data.attributes });
      console.log(response.data);
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
      } else {
        console.log('ERROR', err);
      }
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleOpenConfirmDelete = (attribute) => {
    this.setState({
      showConfirmDelete: true,
      actionName: attribute.name,
      selectedAttribute: attribute,
    });
  };

  handleDelete = async () => {
    const { selectedAttribute } = this.state;
    this.setState({ isDeleting: true });
    try {
      await axios.post(`attribute/delete/${selectedAttribute.id}`);
      this.setState({ showConfirmDelete: false });
      this.fetchAttributes();
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
      } else {
        console.log('ERROR', err);
      }
    } finally {
      this.setState({ isDeleting: false });
    }
  }

  render() {
    const {
      editAttribute,
      newAttribute
    } = this.props;
    const {
      showConfirmDelete,
      actionName,
      isLoading,
      attributes,
      showNotification,
      notificationMessage
    } = this.state;
    return !isLoading ? (
      <>
        <ConfirmDeleteModal
          onClose={ () => this.setState({ showConfirmDelete: false }) }
          show={ showConfirmDelete }
          thingToRemoveType=" atributo "
          thingToRemoveName={ actionName }
          onConfirm={ this.handleDelete }
        />
        <Row style={ { marginTop: "2%" } }>
          <Col md={ 12 }>
            { showNotification &&
              <ErrorNotification
                notificationMessage={ notificationMessage }
                closeNotification={ () => this.setState({ showNotification: false }) }
              />
            }
            <Row>
              <Col md={ 8 } style={ { paddingLeft: "2%" } }>
                <Button
                  bsStyle="primary"
                  fill
                  type="button"
                  onClick={ () => newAttribute() }
                  disabled={ attributes.length === 10 }>
                  Nuevo atributo
                </Button>
              </Col>
            </Row>
            <Table striped hover style={ { marginTop: "2%" } }>
              <thead>
                <tr>
                  <th>Sticker</th>
                  <th>Nombre</th>
                  <th>Valor</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                { attributes.map((attribute) => {
                  return (
                    <tr key={ attribute.id }>
                      <td>
                        <img alt={ attribute.sticker } src={ gRoute + monitorStickersFolder + attribute.sticker } style={ styles.iconList } />
                      </td>
                      <td>{ attribute.name }</td>
                      <td>{ attribute.value }</td>
                      <td>
                        <Button bsStyle="success" bsSize="sm" fill title="editar" onClick={ () => editAttribute(attribute) } ><i className="fa fa-edit"></i></Button>
                        <Button bsStyle="danger" bsSize="sm" fill title="Eliminar" onClick={ () => this.handleOpenConfirmDelete(attribute) } ><i className="fa fa-trash"></i></Button>
                      </td>
                    </tr>
                  );
                }) }
              </tbody>
            </Table>
          </Col>
        </Row>
      </>
    ) : (
        <Loader
          type="Rings"
          color="#00BFFF"
          height={ 100 }
          width={ 100 }
          style={ { display: 'flex', justifyContent: 'center' } }
        />
      );
  }
}

export default AttributeList;
