import React, { Component } from "react";
import { Grid } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import ExtraList from 'views/extras/ExtraList';
import ExtraEdit from 'views/extras/ExtraEdit';

class Extracurricular extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0,
      extraData: {},
      currentPage: 1,
    };
  }

  handleBackToList = () => {
    this.setState({ key: 0 });
  };
  handleEditExtra = (extraData) => {
    this.setState({ key: 1, extraData: extraData });
  };

  goToNextPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage + 1 }));
  }

  goToPreviewsPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage - 1 }));
  }

  render() {
    const {
      key,
      extraData,
      currentPage
    } = this.state;
    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <div >
              <Card
                cardStyle={ {
                  boxShadow: 'none',
                } }
                content={
                  <Grid fluid>
                    {
                      {
                        0: (
                          <ExtraList
                            detailExtra={ this.handleEditExtra }
                            currentPage={ currentPage }
                            goToNextPage={ this.goToNextPage }
                            goToPreviousPage={ this.goToPreviewsPage }
                          />
                        ),
                        1: <ExtraEdit returnToList={ this.handleBackToList } extraData={ extraData } />,
                      }[key]
                    }
                  </Grid>
                }
              />
            </div>
          }
        />
      </div>
    );
  }
}

export default Extracurricular;
