import React from 'react'
import { Image } from "react-bootstrap";

class HeaderQuestionnaire extends React.Component {
  render() {
    return (
      <header style={{display:'flex'}} className="my-3">
        <Image src='./logo.jpg' style={{margin:'30px auto 0px auto',width:'100%'}}/>
      </header>
    );
  }
}

export default HeaderQuestionnaire