import React, { Component } from "react";

import { Card } from "components/Card/Card.jsx";
import { Grid, Row, Table, Col, Pager } from "react-bootstrap";
import axios from 'components/axios';
import SearchAttendance from 'components/Search/SearchAttendance';
import Button from 'components/CustomButton/CustomButton';
import { gRoute } from "variables/DomainRoutes.jsx";
import ModalExcel from 'components/ModalExcel';

class Attendace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      attendances: [],
      openModal: false
    };
    this.controlModal = this.controlModal.bind(this);
  }

  controlModal(show) {
    this.setState({ openModal: show || false });
  }

  async componentDidMount() {
    this.fetchAttendances();
  }

  fetchAttendances = async () => {
    this.setState({ isLoading: true });
    const { currentPage } = this.state;
    try {
      const response = await axios.post('/assist/list', {
        current_page: currentPage,
      });
      this.setState({
        attendances: response.data.Assists.data,
        totalOfPages: response.data.Assists.last_page,
        totalOfRecords: response.data.Assists.total,
      });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  goToNextPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage + 1 }));
  }

  goToPreviousPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage - 1 }));
  }

  changeTableData = (response) => {
    this.setState({
      attendances: response.data.Assists.data,
      totalOfPages: response.data.Assists.last_page,
      totalOfRecords: response.data.Assists.total,
    });
  }

  render() {
    const {
      attendances,
      currentPage,
      totalOfRecords,
      totalOfPages,
      urlExcel,
      loading,
      openModal
    } = this.state;

    return (
      <div style={{ marginLeft: '30px', marginRight: '30px' }}>
        <ModalExcel url="/assist/excel" isOpen={openModal} controlModal={this.controlModal} />
        <Card
          cardStyle={{
            boxShadow: 'none',
          }}
          content={
            <Grid fluid>
              <Row>
                <Col md={11}>
                  <SearchAttendance
                    route={"/assist/search"}
                    currentPage={currentPage}
                    changeTableData={this.changeTableData}
                  />
                </Col>
                <Col md={12}>
                  <Button
                    bsStyle="primary"
                    pullRight
                    onClick={() => this.controlModal(true)}
                  >
                    Exportar Excel
                  </Button>
                </Col>
                <Col md={12}>
                  <Table striped hover >
                    <thead>
                      <tr>
                        <th>Estudiante</th>
                        <th>Nivel</th>
                        <th>Grado</th>
                        <th>Grupo</th>
                        <th>Fecha y hora</th>
                        <th>Tipo</th>
                        <th>Registrado por</th>
                      </tr>
                    </thead>
                    <tbody>
                      {attendances.map((att) => {
                        return (
                          <tr key={att.id}>
                            <td>{att.student && `${att.student.name} ${att.student.last_name}`}</td>
                            <td>{att.student && att.student.level && att.student.level.name}</td>
                            <td>{att.student && att.student.grade && att.student.grade.name}</td>
                            <td>{att.student && att.student.group && att.student.group.name}</td>
                            <td>{att.created_at}</td>
                            <td>{att.kind === true ?
                              <span className="label label-success">Ingreso </span>
                              :
                              <span className="label label-warning">Salida </span>
                            }</td>
                            <td>{att.name}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  {attendances.length > 0 && (
                    <>
                      <Pager>
                        <Pager.Item
                          previous
                          disabled={currentPage === 1}
                          onClick={this.goToPreviousPage}
                        >
                          <i className="fa fa-chevron-left"></i>  Anterior
                        </Pager.Item>
                        <Pager.Item>

                          {currentPage}/{totalOfPages}

                        </Pager.Item>
                        <Pager.Item
                          disabled={currentPage === totalOfPages}
                          next
                          onClick={this.goToNextPage}
                        >
                          Siguiente <i className="fa fa-chevron-right"></i>
                        </Pager.Item>
                      </Pager>
                      <Pager>
                        <Pager.Item>
                          Total: {totalOfRecords} registros
                        </Pager.Item>
                      </Pager>
                    </>
                  )}
                </Col>
              </Row>

            </Grid>
          }
        />
      </div>
    );
  }
}

export default Attendace;