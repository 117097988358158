import React, { Component } from "react";
import { Card } from "components/Card/Card.jsx";
import { Grid, Row, Table, Col, Pager } from "react-bootstrap";
import { mensajeError500 } from "variables/Variables.jsx";
import axios from 'components/axios';
import Button from 'components/CustomButton/CustomButton';
import Loader from 'react-loader-spinner'
import Checkbox from 'components/CustomCheckbox/CustomCheckbox';
import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal.jsx';
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import PayHeader from 'views/pay/PayHeader.jsx'

class PayList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      payments: [],
      totalOfPages: 0,
      totalOfRecords: 0,
      selectedCheckboxes: new Set(),
      areCheckboxSelected: false,
      showConfirmDelete: false
    };
  }

  async componentDidMount() {
    this.fetchPayments()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentPage !== this.props.currentPage) {
      this.fetchPayments();
    }
  }

  fetchPayments = async () => {
    const { currentPage } = this.props;
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('/payment/listall', {
        current_page: currentPage,
      });
      this.setState({
        payments: response.data.payments.data,
        totalOfPages: response.data.payments.last_page,
        totalOfRecords: response.data.payments.total
      });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  toggleCheckbox(number) {
    const actual = this.state.selectedCheckboxes;
    if (actual.has(number)) {
      actual.delete(number);
    } else {
      actual.add(number);
    }
    this.setState({ selectedCheckboxes: actual })
  }

  handleOpenConfirmDelete = (pay) => {
    this.setState({
      showConfirmDelete: true,
      actionName: `con el concepto: ${pay.concept} con monto: ${pay.mount} `,
      selectedPay: pay,
    });
  };

  handleDelete = async () => {
    const { selectedPay } = this.state;
    this.setState({ isDeleting: true });
    try {
      await axios.post('payment/delete', {
        payment_id: selectedPay.id,
      });
      this.setState({ showConfirmDelete: false });
      this.fetchPayments();
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
      console.log('ERROR', err);
      this.setState({ isDeleting: false, showConfirmDelete: false });
    } finally {
      this.setState({ isDeleting: false });
    }
  }
  joinIds = () => {
    const { selectedCheckboxes } = this.state;
    let ids = "";
    selectedCheckboxes.forEach((item) => {
      ids = ids + "," + item
    })
    return ids.substring(1);
  }

  handleDeleteMassive = async () => {
    this.setState({ isDeleting: true });
    try {
      await axios.post(`payment/massive/delete`, {
        id: this.joinIds()
      });
      this.setState({ showConfirmDelete: false });
      this.fetchPayments();
      this.state.selectedCheckboxes.clear()
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
        console.log('ERROR', err);
      }
      console.log('ERROR', err);
      this.setState({ isDeleting: false, showConfirmDelete: false });
    } finally {
      this.setState({ isDeleting: false });
    }
  }

  changeTableData = (response) => {
    this.setState({
      payments: response.data.payment.data,
      totalOfPages: response.data.payment.last_page,
    });
  }

  render() {

    const {
      detailPay,
      editPay,
      currentPage,
      goToNextPage,
      goToPreviousPage
    } = this.props;
    const {
      isLoading,
      payments,
      totalOfPages,
      totalOfRecords,
      showConfirmDelete,
      actionName,
      showNotification,
      notificationMessage,
      selectedCheckboxes,
      loading,
      urlExcel
    } = this.state;
    return !isLoading ? (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <Grid fluid>
              <Row >
                <PayHeader
                  deleteDisabled={ selectedCheckboxes.size === 0 }
                  deleteMasive={ this.handleDeleteMassive }
                  route="/payment/search"
                  changeTableData={ this.changeTableData }
                  currentPage={ currentPage }
                  loading={ loading }
                  urlExcel={ urlExcel }
                />
                <ConfirmDeleteModal
                  onClose={ () => this.setState({ showConfirmDelete: false }) }
                  show={ showConfirmDelete }
                  thingToRemoveType="pago"
                  thingToRemoveName={ actionName }
                  onConfirm={ this.handleDelete }
                />
                <Col md={ 12 }>
                  { showNotification &&
                    <ErrorNotification
                      notificationMessage={ notificationMessage }
                      closeNotification={ () => this.setState({ showNotification: false }) }
                    />
                  }
                  <Table striped hover >
                    <thead>
                      <tr>
                        <th></th>
                        <th>Concepto</th>
                        <th>Matrícula</th>
                        <th>Padre</th>
                        <th>Estudiante</th>
                        <th>Monto</th>
                        <th>Estado</th>
                        <th>Tipo</th>
                        <th>Modo</th>
                        <th>Enviado</th>
                        <th>acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      { payments.map((pay) => {
                        return (
                          <tr key={ pay.id }>
                            <td>
                              { pay.status === false &&
                                <Checkbox
                                  number={ "pay" + pay.id }
                                  onClick={ () => { this.toggleCheckbox(pay.id) } }
                                /> }
                            </td>
                            <td>{ pay.concept }</td>
                            <td>{ pay.student ? pay.student.code : "eliminado" }</td>
                            <td>{ pay.tutor ? `${pay.tutor.name} ${pay.tutor.last_name}` : "eliminado" }</td>
                            <td>{ pay.student ? `${pay.student.name} ${pay.student.last_name}` : "eliminado" }</td>
                            <td>{ pay.mount }</td>
                            <td>
                              { pay.status === true ?
                                <span className="label label-success">Pagado</span>
                                :
                                <span className="label label-warning">En espera</span>
                              }
                            </td>
                            <td>
                              { pay.kind === 1 ?
                                "Manual"
                                :
                                "CSV"
                              }
                            </td>
                            <td>{ pay.mode }</td>
                            <td>{ pay.created_at }</td>
                            <td>
                              <Button bsStyle="info" bsSize="sm" fill title="Ver detalles" onClick={ () => detailPay(pay) }><i className="fa fa-search"></i></Button>
                              {
                                !pay.status && (
                                  <>
                                    <Button bsStyle="success" bsSize="sm" fill title="Editar" onClick={ () => editPay(pay) }><i className="fa fa-pencil"></i></Button>
                                    <Button bsStyle="danger" bsSize="sm" fill title="Eliminar" onClick={ () => this.handleOpenConfirmDelete(pay) }><i className="fa fa-trash"></i></Button>
                                  </>
                                )
                              }
                            </td>
                          </tr>
                        );
                      }) }
                    </tbody>
                  </Table>
                  { payments.length > 0 && (
                    <>
                      <Pager>
                        <Pager.Item
                          previous
                          disabled={ currentPage === 1 }
                          onClick={ goToPreviousPage }
                        >
                          <i className="fa fa-chevron-left"></i>  Anterior
                      </Pager.Item>
                        <Pager.Item>

                          { currentPage }/{ totalOfPages }

                        </Pager.Item>
                        <Pager.Item
                          disabled={ currentPage === totalOfPages }
                          next
                          onClick={ goToNextPage }
                        >
                          Siguiente <i className="fa fa-chevron-right"></i>
                        </Pager.Item>
                      </Pager>
                      <Pager>
                        <Pager.Item>
                          Total: { totalOfRecords } registros
                </Pager.Item>
                      </Pager>
                    </>
                  ) }
                </Col>
              </Row>
            </Grid>
          }
        />
      </div>
    ) : (
        <Loader
          type="Rings"
          color="#00BFFF"
          height={ 100 }
          width={ 100 }
          style={ { display: 'flex', justifyContent: 'center' } }
        />
      );
  }
}

export default PayList;