import React, { Component } from "react";
import {
  Grid
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import PayList from 'views/pay/PayList';
import PayDetail from 'views/pay/PayDetail';
import PayEdit from 'views/pay/PayEdit';
class Pay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0,
      payData: {},
      currentPage: 1,
    };
  }

  handleBackToList = () => {
    this.setState({ key: 0 });
  };

  handleDetailPay = (payData) => {
    this.setState({ key: 1, payData: payData });
  };

  handleEditPay = (payData) => {
    this.setState({ key: 2, payData: payData });
  };

  goToNextPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage + 1 }));
  }

  goToPreviewsPage = () => {
    this.setState((currentState) => ({ currentPage: currentState.currentPage - 1 }));
  }

  render() {
    const {
      key,
      payData,
      currentPage
    } = this.state;

    return (
      <div style={ { marginLeft: '30px', marginRight: '30px' } }>
        <Card
          cardStyle={ {
            boxShadow: 'none',
          } }
          content={
            <Grid fluid>
              {
                {
                  0: (
                    <PayList
                      detailPay={ this.handleDetailPay }
                      editPay={ this.handleEditPay }
                      currentPage={ currentPage }
                      goToNextPage={ this.goToNextPage }
                      goToPreviousPage={ this.goToPreviewsPage }
                    />
                  ),
                  1: <PayDetail returnToList={ this.handleBackToList } payData={ payData } />,
                  2: <PayEdit returnToList={ this.handleBackToList } payData={ payData } />,
                }[key]
              }

            </Grid>
          }
        />
      </div>
    );
  }
}

export default Pay;
