import React from 'react'
import axios from 'components/axios';
import Loader from 'react-loader-spinner'
import { Table, Col, Row, Pager } from "react-bootstrap";
import { gRoute } from "variables/DomainRoutes.jsx";
import Checkbox from 'components/CustomCheckbox/CustomCheckbox';
import { thQuestionnaireListArray, mensajeError500 } from "variables/Variables.jsx";
import Button from 'components/CustomButton/CustomButton';
import SearchInputStudent from 'components/Search/SearchInputStudentFixed';
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'

class TutorQuestionnaireList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCheckboxes: new Set(),
      isLoading: false,
      isDeleting: false,
      totalOfPages: 0,
      totalOfRecords: 0,
      students: [],
      showConfirmDelete: false,
      actionName: "",
      selectedStudent: {},
      areCheckboxSelected: false,
      searchShowNotification: false,
      searchNotificationType: '',
      searchNotificationMessage: '',
      searchIsLoading:false,
      searchTarget:'',
      searchGradeId:'',
      searchGroupId:'',
      searchLevelId:'',
      searchRoute:''
    };
  }

  async componentDidMount() {
    this.setState({ currentPage: this.props.currentPage })
    this.fetchStudents();
  }

  fetchStudents = async () => {
    this.setState({ isLoading: true });
    const {tutor_id} = JSON.parse(localStorage.getItem('USER'));
    try {
      let response = {};
      if(tutor_id){
        response = await axios.post(`/show/suitable/${tutor_id}`);
      }
      this.setState({
        students: response.data.tutor.student,
      });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const {
      students,
      isLoading,
      showNotification,
      notificationMessage,
      loading
    } = this.state;
    return !isLoading ? (
      <Row>
        <Col md={ 12 } style={ { padding: "2%" } }>
            { showNotification &&
              <ErrorNotification
                notificationMessage={ notificationMessage }
                closeNotification={ () => this.setState({ showNotification: false }) }
              />
            }
            <Table striped hover >
              <thead>
                <tr>
                  { thQuestionnaireListArray.map((prop, key) => {
                    return <th key={ key }>{ prop }</th>;
                  }) }
                </tr>
              </thead>
              <tbody>
                { students.map((student) => {
                  return (
                    <tr key={ student.id }>
                      <td>{ student.name }</td>
                      <td>{ student.last_name }</td>
                      <td>{ student.code }</td>
                      <td> { student.gender === true ? "F" : "M" } </td>
                      <td>{ student.level && student.level.name }</td>
                      <td>{ student.grade && student.grade.name }</td>
                      <td>{ student.group && student.group.name }</td>
                      <td>{ student.subject }</td>
                      <td>{ student.schedule }</td>
                      <td>{ student.test_date }</td>
                      <td>{ student.suitable === true ? 'Apto' : student.suitable === false ? 'No es Apto' : '-' }</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
      </Row>
    ) 
    : (
      <Loader
        type="Rings"
        color="#00BFFF"
        height={ 100 }
        width={ 100 }
        style={ { display: 'flex', justifyContent: 'center' } }
      />
    );
  }
}

export default TutorQuestionnaireList
