

import React from 'react'
import {
  Row,
  Col,
} from "react-bootstrap";
import { FormGroup, ControlLabel, FormControl, Alert } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import ModalNewRole from 'views/roles/modalNewRole';
import styles from './styles'
import axios from 'components/axios';
import Loader from 'react-loader-spinner'
import { mensajeError500 } from "variables/Variables.jsx";

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      lastname: '',
      roleId: '',
      email: '',
      password: '',
      passwordRepeat: '',
      showNotification: false,
      notificationMessage: '',
      seePassword: false,
      showNewRoleModal: false,
      roles: []
    };
  }

  componentDidMount() {
    this.fetchRoles();
  }
  fetchRoles = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await axios.post('/rol/select/list');
      this.setState({
        roles: response.data.roles
      });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const {
      name,
      lastname,
      roleId,
      email,
      password,
      passwordRepeat,
    } = this.state;
    if (password !== passwordRepeat) {
      this.setState({
        showNotification: true,
        notificationMessage: "Las contraseñas no coinciden"
      });
      return;
    }
    if (password.length < 6) {
      this.setState({
        showNotification: true,
        notificationMessage: "La contraseña debe contener almenos 6 caracteres"
      });
      return;
    }
    this.setState({ isSubmitting: true });
    try {
      await axios.post('user/add', {
        name: name,
        last_name: lastname,
        role_id: roleId,
        institution_id: 1,
        email,
        password,
      });
      this.props.returnToList();
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            showNotification: true,
            notificationType: "danger",
            notificationMessage: mensajeError500
          });
        }
      } else {
        console.log('ERROR', err);
      }
      console.log('ERROR', err);
    } finally {
      this.setState({ isSubmitting: false });
    }
  }

  handleShowNewRoleModal = () => {
    this.setState({ showNewRoleModal: true });
  };

  handleCloseNewRoleModal = () => {
    this.setState({ showNewRoleModal: false });
  };

  render() {
    const {
      name,
      lastname,
      roleId,
      email,
      password,
      passwordRepeat,
      seePassword,
      showNewRoleModal,
      isSubmitting,
      showNotification,
      notificationMessage,
      isLoading,
      roles
    } = this.state;
    const {
      returnToList
    } = this.props;
    return !isSubmitting ? (
      <>
        <ModalNewRole
          onClose={ this.handleCloseNewRoleModal }
          open={ showNewRoleModal }
          onUserForm={ true }
          reloadRoles={ () => this.fetchRoles() }
        />
        <form onSubmit={ this.handleSubmit }>
          <Row>
            <Col md={ 12 } style={ { padding: "2%" } }>
              <h4>Registrar un usuario</h4>
              { showNotification && (
                <Alert bsStyle="danger">
                  <button type="button" aria-hidden="true" className="close" onClick={ () => this.setState({ showNotification: false }) }>
                    &#x2715;
                  </button>
                  <span>{ notificationMessage }</span>
                </Alert>
              ) }
              <Row >
                <Col md={ 6 }>
                  <Col md={ 10 }>
                    <FormGroup>
                      <ControlLabel> NOMBRES </ControlLabel>
                      <FormControl required type="text" name="name" value={ name } onChange={ e => this.onChange(e) } />
                    </FormGroup>
                  </Col>
                  <Col md={ 2 }></Col>
                  <Col md={ 10 }>
                    <FormGroup>
                      <ControlLabel> APELLIDOS </ControlLabel>
                      <FormControl required type="text" name="lastname" value={ lastname } onChange={ e => this.onChange(e) } />
                    </FormGroup>
                  </Col>
                  <Col md={ 2 }></Col>
                  <Col md={ 10 }>
                    <FormGroup >
                      <ControlLabel>ROL</ControlLabel>
                      { !isLoading ? (
                        <FormControl required componentClass="select" name="roleId" value={ roleId } onChange={ e => this.onChange(e) }>
                          <option value="">Seleccione una opción</option>
                          { roles.map((item) =>
                            <option key={ item.id } value={ item.id }>{ item.name }</option>
                          ) }
                        </FormControl>
                      ) : (
                          <Loader
                            type="Rings"
                            color="#00BFFF"
                            height={ 30 }
                            width={ 30 }
                            style={ { display: 'flex', justifyContent: 'center' } }
                          />
                        ) }

                    </FormGroup>
                  </Col>
                  <Col md={ 2 }>
                    <Button
                      bsStyle="primary"
                      fill
                      style={ styles.newRoleButton }
                      onClick={ this.handleShowNewRoleModal }
                    >Nuevo Rol</Button>
                  </Col>
                </Col>

                <Col md={ 6 }>
                  <Col md={ 10 }>
                    <FormGroup>
                      <ControlLabel> CORREO ELECTRÓNICO </ControlLabel>
                      <FormControl required type="email" name="email" value={ email } onChange={ e => this.onChange(e) } />
                    </FormGroup>
                  </Col>
                  <Col md={ 2 }></Col>
                  <Col md={ 10 } style={ { marginBottom: "15px" } }>
                    <FormGroup >
                      <ControlLabel> CONTRASEÑA </ControlLabel>
                      <Col md={ 11 } style={ { paddingLeft: "0px" } }>
                        <FormControl required type={ seePassword === false ? "password" : "text" } name="password" value={ password } onChange={ e => this.onChange(e) } />
                      </Col>
                      <Col md={ 1 }>
                        {
                          seePassword === false ?
                            (<Button bsStyle="default" style={ styles.passButton } onClick={ () => this.setState({ seePassword: true }) }><i className="fa fa-eye"></i></Button>) :
                            (<Button bsStyle="primary" style={ styles.passButton } onClick={ () => this.setState({ seePassword: false }) }><i className="fa fa-eye-slash"></i></Button>)
                        }
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md={ 2 }></Col>
                  <Col md={ 10 }>
                    <FormGroup>
                      <ControlLabel> CONFIRMAR CONTRASEÑA </ControlLabel>
                      <FormControl required type="password" name="passwordRepeat" value={ passwordRepeat } onChange={ e => this.onChange(e) } />
                    </FormGroup>
                  </Col>
                </Col>
              </Row>
              <Row>
                <Col md={ 12 } style={ { marginLeft: "0px" } }>
                  <Button bsStyle="success" pullRight fill type="submit" style={ styles.saveButton }>
                    <i className="fa fa-save"></i> Guardar
                  </Button>
                  <Button bsStyle="danger" pullRight fill type="button" onClick={ returnToList } style={ styles.saveButton }>
                    <i className="fa fa-chevron-left"></i> Regresar
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </>
    ) : (
        <Loader
          type="Rings"
          color="#00BFFF"
          height={ 100 }
          width={ 100 }
          style={ { display: 'flex', justifyContent: 'center' } }
        />
      );
  }
}
export default UserForm