import React from "react";
import { Col, Row, Grid } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton";

class ResponsiveCard extends React.Component {
  render() {
    return (
      <div style={{ marginLeft: "30px", marginRight: "30px" }}>
        <Card
          cardStyle={{
            boxShadow: "none",
          }}
          content={
            <Grid fluid>
              <Row>
                <Col md={12} style={{ padding: "2%", display: 'block', textAlign: 'center' }}>
                  <h3 style={{marginBottom:'3rem'}}><b>
                    Favor de imprimir y llenar los formatos descargados. Los
                    cuales deberá entregar en la entrada de su plantel
                  </b></h3>
                  <a
                    rel="noreferrer"
                    aria-label="redirect"
                    target="_blank"
                    without="true"
                    download
                    href="https://difemadmin.aplicacionescolar.net/static/CARTARESPONSIVA.docx"
                  >
                    <Button
                      style={{ PointerEvent: "none" }}
                      bsStyle="primary"
											bsSize='lg'
                    >
                      Descargar Aqui
                    </Button>
                  </a>
                </Col>
              </Row>
            </Grid>
          }
        />
      </div>
    );
  }
}

export default ResponsiveCard;
