import React from 'react'
import Header from './Header';
import { Grid, Row, Col, Modal } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton';
import { Form, FormGroup, FormControl, ControlLabel } from "react-bootstrap";

class Questionnaire extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tutor:[],
      showmodalinfo:false,
      showmodalresult:false,
      student:{},
      disabled_form_input:false,
      form:{
        id:null,
        form_name:'',
        subject:'',
        schedule:'',
        question1:'',
        question2_1:'',
        question2_2:'',
        question2_3:'',
        question2_4:'',
        question2_5:'',
        question2_6:'',
        question2_7:'',
        question2_8:'',
        question3:'',
        question4:'',
      },
      modalresultvalue:''
    };
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    fetch(`https://difemapi.aplicacionescolar.net/api/v1/show/suitable/${id}`, {method: 'POST', redirect: 'follow'})
      .then(response => response.json())
      .then(result => {
        if(!result.error){
          this.setState({ tutor: result.tutor })          
        }else{
          this.setState({tutor: {error:result.error } })
        }
      })
      .catch(error => this.setState({tutor: {error:error } }));
  }

  ShowQuestionnaire = (name,last_name,id,subject,schedule,suitable) => {
    this.setState({ student:{
      name,
      last_name,
      id,
      subject,
      schedule,
      suitable
    }})
    this.setState({ form:{...this.state.form,
      form_name:`${name ? name : ''} ${last_name ? last_name : ''}`, 
      subject: subject ? subject : '', 
      schedule: schedule ? schedule : '',
      id:id
    } })
    this.setState({ showmodalinfo: true })
  }

  onChange = ({target}) => {
    this.setState({ form:{...this.state.form,[target.name]: target.value} })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({...this.state ,disabled_form_input: true})
    const {id,subject,schedule,question1,question2_1,question2_2,question2_3,question2_4,question2_5,question2_6,question2_7,question2_8,question3,question4} = this.state.form;
    let question2
    if(question2_1 === "1" || question2_2 === "1" || question2_3 === "1" || question2_4 === "1" || question2_5 === "1" || question2_6 === "1" || question2_7 === "1" || question2_8 === "1"){
      question2="1"
    }else{
      question2="0"
    }

    var formdata = new FormData();
    formdata.append("subject", subject);
    formdata.append("schedule", schedule);
    formdata.append("question_1", question1);
    formdata.append("question_2", question2);
    formdata.append("question_3", question3);
    formdata.append("question_4", question4);
    
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(`https://difemapi.aplicacionescolar.net/api/v1/set/suitable/${id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        this.setState({ showmodalinfo: false })
        this.setState({ showmodalresult: true })
        if(!result.error){
          this.setState({ modalresultvalue: result.APTO })
          let newStudentValue=this.state.tutor.student;
          newStudentValue.forEach(element => {
            if(element.id === id){
              element.suitable = result.APTO === 'APTO' ? true : result.APTO === 'NO APTO' ? false : null
            }
          });
          this.setState({...this.state ,disabled_form_input: false})
        }else{
          this.setState({ modalresultvalue: result.error })
          this.setState({...this.state ,disabled_form_input: false})
        }

      })
      .catch(error => console.log('error', error));
  }

  render() {
    const {
      name,
      last_name,
      student,
      error
    } = this.state.tutor;

    if(!name || error){
      return null
    }

    return (
      <Grid>
       <Header/>
        <hr/>
        <Row>
          <h2 className="text-center font-weight-bold">Bienvenido <b>{name} {last_name}</b></h2>
          <h3 className="text-center">Seleccione el alumno a evaluar</h3>
          <hr/>
            {student.map(({name,last_name,id,subject,schedule,suitable}) => (
              <Col key={id} md={12} sm={6}>
                <Button 
                  onClick={ () => {this.ShowQuestionnaire(name,last_name,id,subject,schedule,suitable)}} 
                  style={{width:'100%'}} 
                  bsStyle="primary" 
                  fill 
                  type="button"
                  style={{marginBottom:'10px',width: '100%'}}
                >
                  <h4 style={{marginTop:'5px',marginBottom:'0px'}}><b>{name} {last_name}</b></h4>
                  <div style={{background:'#fff',borderRadius:'10px'}}>
                    <h4 style={{marginTop:'5px',marginBottom:'5px',color:'#000'}}>{suitable === true ? 'APTO':suitable === false ? 'NO APTO' : '-' }</h4>
                  </div>
                </Button>
              </Col>
            ))}
        </Row>
        <Modal
          bsSize="lg"
          show={this.state.showmodalinfo} 
          onHide={() => this.setState({ showmodalinfo: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Formulario DIFEM
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4 style={{marginTop:'0px'}}>{this.state.student.name} {this.state.student.last_name}</h4>
            <h4 style={{marginTop:'0px'}} className="text-right">Evaluación actual: {this.state.student.suitable === true ? 'APTO':this.state.student.suitable === false ? 'NO APTO' : '-' }</h4>
            <hr></hr>
            <Form onSubmit={ this.handleSubmit }>
              <FormGroup>
                <div style={{display:'flex'}}>
                  <div style={{width:'48%'}}>
                    <ControlLabel style={ { marginTop: "15px" } }>Curso</ControlLabel>
                    <FormControl value={this.state.form.subject} required type="text" name="subject" onChange={ e => this.onChange(e) }/>
                  </div>
                  <div style={{width:'48%',marginLeft:'auto'}}>
                    <ControlLabel style={ { marginTop: "15px" } }>Horario</ControlLabel>
                    <FormControl value={this.state.form.schedule} required type="text" name="schedule" onChange={ e => this.onChange(e) }/>
                  </div>
                </div>
                <hr/>
                <p>Antes de ingresar a las instalaciones de la Escuela, solicitamos entregues este cuestionario a la persona responsable de realizar el filtro. Si respondiste afirmativamente a cualquiera de los siguientes puntos te sugerimos: <b>“QUEDARTE EN CASA”</b> porque tu salud y la mía son responsabilidad de TODOS.</p>
                <p><b>1.- ¿Actualmente presentas fiebre o tos que puedas atribuir a otra condición médica?</b></p>
                <div style={{display: 'flex',alignItems:'center',justifyContent:'space-around'}}>
                  <div style={{display: 'flex'}}>
                    <ControlLabel style={ { marginTop: "15px" } }>Si</ControlLabel>
                    <FormControl value="1" required style={{width:'25px',marginLeft:'8px'}} type="radio" name="question1" onChange={ e => this.onChange(e) }/>
                  </div>
                  <div style={{display: 'flex'}}>
                    <ControlLabel style={ { marginTop: "15px" } }>No</ControlLabel>
                    <FormControl value="0" required style={{width:'25px',marginLeft:'8px'}} type="radio" name="question1" onChange={ e => this.onChange(e) }/>
                  </div>
                </div>
                <p><b>2.- ¿Presentas alguno de los siguientes síntomas?</b></p>
                <div style={{display: 'flex',width:'77.7%'}}>
                  <ControlLabel style={ { marginTop: "15px" } }>Fatiga</ControlLabel>
                  <div style={{display:'flex',marginLeft:'auto'}}>
                    <ControlLabel style={ { marginTop: "15px",marginRight:'5px' } }>Si</ControlLabel>
                    <FormControl value="1" required style={{width:'25px',marginLeft:'auto'}} type="radio" name="question2_1" onChange={ e => this.onChange(e) }/>
                  </div>
                  <div style={{display:'flex',marginLeft:'8px'}}>
                    <ControlLabel style={ { marginTop: "15px",marginRight:'5px'} }>No</ControlLabel>
                    <FormControl value="0" required style={{width:'25px',marginLeft:'auto'}} type="radio" name="question2_1" onChange={ e => this.onChange(e) }/>
                  </div>
                </div>  
                <div style={{display: 'flex',width:'77.7%'}}>
                  <ControlLabel style={ { marginTop: "15px" } }>Congestión o secreción nasal</ControlLabel>
                  <div style={{display:'flex',marginLeft:'auto'}}>
                    <ControlLabel style={ { marginTop: "15px",marginRight:'5px' } }>Si</ControlLabel>
                    <FormControl value="1" required style={{width:'25px',marginLeft:'auto'}} type="radio" name="question2_2" onChange={ e => this.onChange(e) }/>
                  </div>
                  <div style={{display:'flex',marginLeft:'8px'}}>
                    <ControlLabel style={ { marginTop: "15px",marginRight:'5px'} }>No</ControlLabel>
                    <FormControl value="0" required style={{width:'25px',marginLeft:'auto'}} type="radio" name="question2_2" onChange={ e => this.onChange(e) }/>
                  </div>
                </div>  
                <div style={{display: 'flex',width:'77.7%'}}>
                  <ControlLabel style={ { marginTop: "15px" } }>Dolor de garanta</ControlLabel>
                  <div style={{display:'flex',marginLeft:'auto'}}>
                    <ControlLabel style={ { marginTop: "15px",marginRight:'5px' } }>Si</ControlLabel>
                    <FormControl value="1" required style={{width:'25px',marginLeft:'auto'}} type="radio" name="question2_3" onChange={ e => this.onChange(e) }/>
                  </div>
                  <div style={{display:'flex',marginLeft:'8px'}}>
                    <ControlLabel style={ { marginTop: "15px",marginRight:'5px'} }>No</ControlLabel>
                    <FormControl value="0" required style={{width:'25px',marginLeft:'auto'}} type="radio" name="question2_3" onChange={ e => this.onChange(e) }/>
                  </div>
                </div>  
                <div style={{display: 'flex',width:'77.7%'}}>
                  <ControlLabel style={ { marginTop: "15px" } }>Dolor de cabeza</ControlLabel>
                  <div style={{display:'flex',marginLeft:'auto'}}>
                    <ControlLabel style={ { marginTop: "15px",marginRight:'5px' } }>Si</ControlLabel>
                    <FormControl value="1" required style={{width:'25px',marginLeft:'auto'}} type="radio" name="question2_4" onChange={ e => this.onChange(e) }/>
                  </div>
                  <div style={{display:'flex',marginLeft:'8px'}}>
                    <ControlLabel style={ { marginTop: "15px",marginRight:'5px'} }>No</ControlLabel>
                    <FormControl value="0" required style={{width:'25px',marginLeft:'auto'}} type="radio" name="question2_4" onChange={ e => this.onChange(e) }/>
                  </div>
                </div>  
                <div style={{display: 'flex',width:'77.7%'}}>
                  <ControlLabel style={ { marginTop: "15px" } }>Diarrea</ControlLabel>
                  <div style={{display:'flex',marginLeft:'auto'}}>
                    <ControlLabel style={ { marginTop: "15px",marginRight:'5px' } }>Si</ControlLabel>
                    <FormControl value="1" required style={{width:'25px',marginLeft:'auto'}} type="radio" name="question2_5" onChange={ e => this.onChange(e) }/>
                  </div>
                  <div style={{display:'flex',marginLeft:'8px'}}>
                    <ControlLabel style={ { marginTop: "15px",marginRight:'5px'} }>No</ControlLabel>
                    <FormControl value="0" required style={{width:'25px',marginLeft:'auto'}} type="radio" name="question2_5" onChange={ e => this.onChange(e) }/>
                  </div>
                </div>  
                <div style={{display: 'flex',width:'77.7%'}}>
                  <ControlLabel style={ { marginTop: "15px" } }>Nauseas o vómito</ControlLabel>
                  <div style={{display:'flex',marginLeft:'auto' }}>
                    <ControlLabel style={ { marginTop: "15px",marginRight:'5px' } }>Si</ControlLabel>
                    <FormControl value="1" required style={{width:'25px',marginLeft:'auto'}} type="radio" name="question2_6" onChange={ e => this.onChange(e) }/>
                  </div>
                  <div style={{display:'flex',marginLeft:'8px'}}>
                    <ControlLabel style={ { marginTop: "15px",marginRight:'5px'} }>No</ControlLabel>
                    <FormControl value="0" required style={{width:'25px',marginLeft:'auto'}} type="radio" name="question2_6" onChange={ e => this.onChange(e) }/>
                  </div>
                </div>  
                <div style={{display: 'flex',width:'77.7%'}}>
                  <ControlLabel style={ { marginTop: "15px" } }>Dolores musculares y/o corporales</ControlLabel>
                  <div style={{display:'flex',marginLeft:'auto' }}>
                    <ControlLabel style={ { marginTop: "15px",marginRight:'5px' } }>Si</ControlLabel>
                    <FormControl value="1" required style={{width:'25px',marginLeft:'auto'}} type="radio" name="question2_7" onChange={ e => this.onChange(e) }/>
                  </div>
                  <div style={{display:'flex',marginLeft:'8px'}}>
                    <ControlLabel style={ { marginTop: "15px",marginRight:'5px'} }>No</ControlLabel>
                    <FormControl value="0" required style={{width:'25px',marginLeft:'auto'}} type="radio" name="question2_7" onChange={ e => this.onChange(e) }/>
                  </div>
                </div>  
                <div style={{display: 'flex',width:'77.7%'}}>
                  <ControlLabel style={ { marginTop: "15px" } }>Perdido recientemente el gusto  y/o olfato</ControlLabel>
                  <div style={{display:'flex',marginLeft:'auto'}}>
                    <ControlLabel style={ { marginTop: "15px",marginRight:'5px' } }>Si</ControlLabel>
                    <FormControl value="1" required style={{width:'25px',marginLeft:'auto'}} type="radio" name="question2_8" onChange={ e => this.onChange(e) }/>
                  </div>
                  <div style={{display:'flex',marginLeft:'8px'}}>
                    <ControlLabel style={ { marginTop: "15px",marginRight:'5px'} }>No</ControlLabel>
                    <FormControl value="0" required style={{width:'25px',marginLeft:'auto'}} type="radio" name="question2_8" onChange={ e => this.onChange(e) }/>
                  </div>
                </div>
                <p><b>3.- ¿Padeces alguna enfermedad crónica degenerativa?</b></p>
                <div style={{display: 'flex', alignItems:'center',justifyContent:'space-around'}}>
                  <div style={{display:'flex'}}>
                    <ControlLabel style={ { marginTop: "15px" } }>Si</ControlLabel>
                    <FormControl value="1" required style={{width:'25px',marginLeft:'8px'}} type="radio" name="question3" onChange={ e => this.onChange(e) }/>                    
                  </div>
                  <div style={{display:'flex'}}>
                    <ControlLabel style={ { marginTop: "15px" } }>No</ControlLabel>
                    <FormControl value="0" required style={{width:'25px',marginLeft:'8px'}} type="radio" name="question3" onChange={ e => this.onChange(e) }/>
                  </div>
                </div>
                <p><b>4.- ¿Sabes si has tenido contacto con alguna persona diagnosticada con COVID 19 en los últimos 14 días?</b></p>
                <div style={{display: 'flex',alignItems:'center',justifyContent:'space-around'}}>
                  <div style={{display:'flex'}}>
                    <ControlLabel style={ { marginTop: "15px" } }>Si</ControlLabel>
                    <FormControl value="1" required style={{width:'25px',marginLeft:'8px'}} type="radio" name="question4" onChange={ e => this.onChange(e) }/>
                  </div>
                  <div style={{display:'flex'}}>
                    <ControlLabel style={ { marginTop: "15px" } }>No</ControlLabel>
                    <FormControl value="0" required style={{width:'25px',marginLeft:'8px'}} type="radio" name="question4" onChange={ e => this.onChange(e) }/>
                  </div>
                </div>
                <Modal.Footer style={{display:'flex'}}>
                  <Button 
                    bsStyle="danger" 
                    fill 
                    style={{width:'50%'}} 
                    onClick={() => this.setState({ showmodalinfo: false })}>
                    Cancelar
                  </Button>
                  <Button 
                    style={{width:'50%'}} 
                    bsStyle="primary" 
                    fill 
                    disabled={this.state.disabled_form_input}
                    type="submit"
                  >
                    Enviar
                  </Button>
                </Modal.Footer>
              </FormGroup>
            </Form>
          </Modal.Body>
        </Modal>
        <Modal 
          bsSize="lg"
          show={this.state.showmodalresult}
          onHide={() => this.setState({ showmodalresult: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Resultado
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h2 className="text-center">{this.state.modalresultvalue}</h2>
          </Modal.Body>
          <Modal.Footer style={{display:'flex'}}>
            <Button 
              bsStyle="success" 
              fill 
              style={{width:'100%'}} 
              onClick={() => this.setState({ showmodalresult: false })}>
              Continuar...
            </Button>
          </Modal.Footer>
        </Modal>
      </Grid>
    );
  }
}

export default Questionnaire