import React from 'react'
import axios from 'components/axios';
import Loader from 'react-loader-spinner'
import { Table, Col, Row, Pager } from "react-bootstrap";
import { gRoute } from "variables/DomainRoutes.jsx";
import { thQuestionnaireListArray, mensajeError500 } from "variables/Variables.jsx";
import Button from 'components/CustomButton/CustomButton';
import SearchInputStudent from 'components/Search/SearchInputStudentFixed';
import ErrorNotification from 'components/Notifications/ErrorNotification.jsx'
import ModalExcel from 'components/ModalExcel';

class QuestionnaireList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCheckboxes: new Set(),
      isLoading: false,
      isDeleting: false,
      totalOfPages: 0,
      totalOfRecords: 0,
      students: [],
      showConfirmDelete: false,
      actionName: "",
      selectedStudent: {},
      areCheckboxSelected: false,
      searchShowNotification: false,
      searchNotificationType: '',
      searchNotificationMessage: '',
      searchIsLoading: false,
      searchTarget: '',
      searchGradeId: '',
      searchGroupId: '',
      searchLevelId: '',
      searchRoute: '',
      openModal: false
    };
    this.controlModal = this.controlModal.bind(this);
  }

  controlModal(show) {
    this.setState({ openModal: show || false });
  }

  async componentDidMount() {
    this.setState({ currentPage: this.props.currentPage })
    this.fetchStudents();
  }

  fetchStudents = async () => {
    const { currentPage } = this.state;
    this.setState({ isLoading: true });
    try {
      let response = {};
      if (currentPage === undefined) {
        this.setState({ currentPage: this.props.currentPage })
        response = await axios.post('student/list', {
          current_page: this.props.currentPage,
        });
      } else {
        response = await axios.post('student/list', {
          current_page: this.state.currentPage,
        });
      }
      this.setState({
        students: response.data.students.data,
        totalOfPages: response.data.students.last_page,
        totalOfRecords: response.data.students.total
      });
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  onClickButtonSearch = (target, gradeId, groupId, levelId, route) => {
    this.setState({ searchTarget: target, searchGradeId: gradeId, searchGroupId: groupId, searchLevelId: levelId, searchRoute: route, currentPage: 1 });
    this.fetchSearch(target, gradeId, groupId, levelId, route, 1);
  }

  onClickPagination = () => {
    const { searchTarget, searchGradeId, searchGroupId, searchLevelId, searchRoute, currentPage } = this.state
    this.fetchSearch(searchTarget, searchGradeId, searchGroupId, searchLevelId, searchRoute, currentPage);
  }

  fetchSearch = async (target, gradeId, groupId, levelId, route, currentPage) => {
    this.setState({ searchActive: true })
    this.setState({ searchIsLoading: true });
    try {
      const response = await axios.post(route, {
        current_page: currentPage,
        name: target,
        level_id: levelId,
        grade_id: gradeId,
        group_id: groupId
      });

      if (response.status === 200) {
        this.setState({
          searchShowNotification: true,
          searchNotificationType: "info",
          searchNotificationMessage: "Búsqueda finalizada. Se encontraron " + response.data.students.total + " coincidecias.",
        });
      }
      this.setState({
        students: response.data.students.data,
        totalOfPages: response.data.students.last_page
      });
    } catch (err) {
      if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
        if (err.response.status === 400) {
          this.setState({
            searchShowNotification: true,
            searchNotificationType: "danger",
            searchNotificationMessage: err.response.data.error
          });
        } else {
          this.setState({
            searchShowNotification: true,
            searchNotificationType: "danger",
            searchNotificationMessage: mensajeError500
          });
        }
      } else {
        this.setState({
          searchShowNotification: true,
          searchNotificationType: "danger",
          searchNotificationMessage: mensajeError500
        });
      }
    } finally {
      this.setState({ searchIsLoading: false });
    }
  }

  render() {
    const {
      students,
      isLoading,
      currentPage,
      totalOfPages,
      totalOfRecords,
      showNotification,
      notificationMessage,
      urlExcel,
      loading,
      openModal
    } = this.state;
    return !isLoading ? (
      <Row>
        <ModalExcel url="/new/excelReport" isOpen={openModal} controlModal={this.controlModal} />
        <Row>
          <Col md={12}>
            <Button
              bsStyle="primary"
              pullRight
              onClick={this.controlModal}
            >
              Exportar Excel
            </Button>
          </Col>
        </Row>
        <Col md={12}>
          <SearchInputStudent
            route={'/student/search'}
            search={this.onClickButtonSearch}
            fetchDetails={this.state}
            closeNotification={() => this.setState({ ...this.state, searchShowNotification: false })}
          />
        </Col>
        <Col md={12} style={{ padding: "2%" }}>
          {showNotification &&
            <ErrorNotification
              notificationMessage={notificationMessage}
              closeNotification={() => this.setState({ showNotification: false })}
            />
          }
          <Table striped hover >
            <thead>
              <tr>
                {thQuestionnaireListArray.map((prop, key) => {
                  return <th key={key}>{prop}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {students.map((student) => {
                return (
                  <tr key={student.id}>
                    <td>{student.name}</td>
                    <td>{student.last_name}</td>
                    <td>{student.code}</td>
                    <td> {student.gender === true ? "F" : "M"} </td>
                    <td>{student.level && student.level.name}</td>
                    <td>{student.grade && student.grade.name}</td>
                    <td>{student.group && student.group.name}</td>
                    <td>{student.subject}</td>
                    <td>{student.schedule}</td>
                    <td>{student.test_date}</td>
                    <td>{student.suitable === true ? 'Apto' : student.suitable === false ? 'No es Apto' : '-'}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {students.length > 0 && (
            <>
              <Pager>
                <Pager.Item
                  previous
                  disabled={currentPage === 1}
                  onClick={() => {
                    this.setState((currentState) => ({ currentPage: currentState.currentPage - 1 }), this.state.searchActive ? this.onClickPagination : this.fetchStudents);
                  }}
                >
                  <i className="fa fa-chevron-left"></i> Anterior
                </Pager.Item>
                <Pager.Item>
                  {currentPage}/{totalOfPages}
                </Pager.Item>
                <Pager.Item
                  disabled={currentPage === totalOfPages}
                  next
                  onClick={() => {
                    this.setState((currentState) => ({ currentPage: currentState.currentPage + 1 }), this.state.searchActive ? this.onClickPagination : this.fetchStudents);
                  }}
                >
                  Siguiente  <i className="fa fa-chevron-right"></i>
                </Pager.Item>
              </Pager>
              <Pager>
                <Pager.Item>
                  Total: {totalOfRecords} registros
                </Pager.Item>
              </Pager>
            </>
          )}
        </Col>
      </Row>
    )
      : (
        <Loader
          type="Rings"
          color="#00BFFF"
          height={100}
          width={100}
          style={{ display: 'flex', justifyContent: 'center' }}
        />
      );
  }
}

export default QuestionnaireList
