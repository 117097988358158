import React from 'react'
import Button from 'components/CustomButton/CustomButton';
import { Form, FormGroup, FormControl, Col, ControlLabel } from "react-bootstrap";
import ModalNotification from 'components/Notifications/ModalNotification.jsx'
import { mensajeError500 } from "variables/Variables.jsx";
import axios from 'components/axios';
import Loader from 'react-loader-spinner'
import Select from 'react-select'

class SearchAttendance extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			target: '',
			from: '',
			to: '',
			showNotification: false,
			notificationMessage: '',
			notificationType: '',
			isLoading: false,
			levelId: '',
			levels: [],
			gradeId: '',
			grades: [],
			groupId: '',
			groups: [],
		};
	}

	async componentDidMount() {
		this.fetchLevels();
	}

	fetchLevels = async () => {
		this.setState({ loadSelect: 1 });
		try {
			const response = await axios.post('/level/list');
			const newData = response.data.Levels.data.map((item) => {
				return { value: item.id, label: item.name }
			})
			newData.push({ value: 0, label: "Todos los niveles" })
			this.setState({ levels: newData })
		} catch (err) {
			console.log('ERROR', err);
		} finally {
			this.setState({ loadSelect: 0 });
		}
	}
	fetchGrades = async (level1) => {
		this.setState({ loadSelect: 2 });
		try {
			const response = await axios.post('/grade/by/level', { level_id: level1 });
			const newData = response.data.Grades.map((item) => {
				return { value: item.id, label: item.name }
			})
			newData.push({ value: 0, label: "Todos los grados" })
			this.setState({ grades: newData })
		} catch (err) {
			console.log('ERROR', err);
		} finally {
			this.setState({ loadSelect: 0 });
		}
	}

	fetchGroups = async () => {
		this.setState({ loadSelect: 3 });
		try {
			const response = await axios.post('/group/full_list');
			const newData = response.data.Groups.map((item) => {
				return { value: item.id, label: item.name }
			})
			newData.push({ value: 0, label: "Todos los grupos" })
			this.setState({ groups: newData })
		} catch (err) {
			console.log('ERROR', err);
		} finally {
			this.setState({ loadSelect: 0 });
		}
	}


	doSearch = async () => {
		const {
			target,
			gradeId,
			groupId,
			levelId,
			from,
			to
		} = this.state;
		const { route, currentPage, changeTableData } = this.props;
		this.setState({ isLoading: true });
		try {
			const response = await axios.post(route, {
				current_page: currentPage,
				query: target,
				from: from + " 00:00:00",
				to: to + " 23:59:59",
				level_id: levelId,
				grade_id: gradeId,
				group_id: groupId,
			});
			if (response.status === 200) {
				this.setState({
					isSuccess: true,
					showNotification: true,
					notificationType: "info",
					notificationMessage: "Búsqueda finalizada. Se encontraron " + response.data.Assists.data.length + " coincidecias."
				});
			}
			changeTableData(response);
		} catch (err) {
			if (typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined') {
				if (err.response.status === 400) {
					this.setState({
						showNotification: true,
						notificationType: "danger",
						notificationMessage: err.response.data.error
					});
				} else {
					this.setState({
						showNotification: true,
						notificationType: "danger",
						notificationMessage: mensajeError500
					});
				}
			} else {
				console.log("ERROR", err)
			}
		} finally {
			this.setState({ isLoading: false });
		}
	}
	onChange(e) {
		this.setState({ [e.target.name]: e.target.value })
	}

	render() {
		const {
			showNotification,
			notificationMessage,
			notificationType,
			isLoading,
			levels,
			grades,
			groups,
			loadSelect
		} = this.state;

		return (
			<Form>
				<FormGroup>
					<Col md={ 10 }>
						<Col md={ 2 } style={ { marginTop: "-30px" } }>
							<ControlLabel style={ { marginRight: "5%" } }>  </ControlLabel>
							<FormControl
								type="text"
								placeholder="Buscar"
								onChange={ (e) => this.setState({ target: e.target.value }) }
								style={ { marginTop: "7%" } }
							/>
						</Col>
						<Col md={ 2 } style={ { marginTop: "-25px" } }>
							<ControlLabel style={ { marginRight: "5%" } }> Desde </ControlLabel>
							<FormControl type="date" name="from" onChange={ e => this.onChange(e) } />
						</Col>
						<Col md={ 2 } style={ { marginTop: "-25px" } }>
							<ControlLabel style={ { marginRight: "5%" } }> Hasta </ControlLabel>
							<FormControl type="date" name="to" onChange={ e => this.onChange(e) } />
						</Col>
						<Col md={ 2 }>
							{ loadSelect === 1 ?
								<Loader
									type="Rings"
									color="#00BFFF"
									height={ 30 }
									width={ 30 }
									style={ { display: 'flex', justifyContent: 'center' } }
								/>
								:
								<Select options={ levels }
									name="levelId" className="basic-single"
									classNamePrefix="select"
									placeholder="nivel ..."
									onChange={ (e) => { this.setState({ 'levelId': e.value }); this.fetchGrades(e.value) } }
								/>
							}
						</Col>
						<Col md={ 2 }>
							{ loadSelect === 2 ?
								<Loader
									type="Rings"
									color="#00BFFF"
									height={ 30 }
									width={ 30 }
									style={ { display: 'flex', justifyContent: 'center' } }
								/>
								:
								<Select options={ grades }
									name="gradeId" className="basic-single"
									classNamePrefix="select"
									onChange={ (e) => { this.setState({ 'gradeId': e.value }); this.fetchGroups() } }
									placeholder="grado ..."
									isDisabled={ grades.length === 0 }
								/>
							}
						</Col>
						<Col md={ 2 }>
							{ loadSelect === 3 ?
								<Loader
									type="Rings"
									color="#00BFFF"
									height={ 30 }
									width={ 30 }
									style={ { display: 'flex', justifyContent: 'center' } }
								/>
								:
								<Select options={ groups }
									name="groupId" className="basic-single"
									classNamePrefix="select"
									onChange={ (e) => this.setState({ 'groupId': e.value }) }
									placeholder="grupo ..."
									isDisabled={ groups.length === 0 }
								/>
							}
						</Col>
					</Col>
					<Col md={ 1 }>
						<Button
							bsStyle="success"
							fill
							block
							style={ { marginTop: "13%" } }
							onClick={ () => this.doSearch() }
						>Buscar</Button>
					</Col>
					<Col md={ 1 }>
						{ isLoading &&
							<>
								<Col md={ 4 }>
									<Loader
										type="Rings"
										color="#00BFFF"
										height={ 40 }
										width={ 40 }
										style={ { display: 'flex', justifyContent: 'center', marginTop: '2%' } }
									/>
								</Col>
								<Col md={ 6 }
									style={ { display: 'flex', marginTop: '3%' } }
								>... Buscando</Col>
							</>
						}
					</Col>
				</FormGroup>
				<Col md={ 12 }>
					{ showNotification &&
						<ModalNotification
							notificationMessage={ notificationMessage }
							closeNotification={ () => this.setState({ showNotification: false }) }
							type={ notificationType }
						/>
					}
				</Col>
			</Form>

		);
	}
}
export default SearchAttendance